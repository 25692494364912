import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CMAT = () => {
  const cmat = useRef(document.createElement("p"));

  const handleCMAT = () => {
    window.location.href="./CMAT";
  };
  const handleCLAT = () => {
    window.location.href="./CLAT";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      {/* <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '4px', display: { xs: 'none', md: 'flex' } }} /> */}
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end", height: '4px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">College of Medicine Admission Test (CMAT)</Typography>
      </Box>      
      <br/>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "10%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleCMAT}>PLM Admission Test (PLMAT)</MenuItem>
        <MenuItem
          onClick={() =>
            cmat.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          College of Medicine Admission Test (CMAT)
        </MenuItem>
        <MenuItem onClick={handleCLAT}>College of Law Admission Test (CLAT)</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "90%"}}>
      <Box ref={cmat} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">The Pamantasan ng Lungsod ng Maynila (PLM) College of Medicine is a professional school committed to excellence in community-oriented medical education, training, research, and service. It has consistently achieved a passing rate of 95 to 100% on the Physician Licensure Exam in recent years. In 2022, the Professional Regulation Commission named the PLM College of Medicine as the second-best performing institution in the licensure examination for that year.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">The PLM College of Medicine accepts applicants for its degree program in Doctor of Medicine on an annual basis. For AY 2024-2025, the application and submissions of requirements is on November 13, 2023 - February 29, 2024. The College Committee on Admissions will select the highest-ranking applicants based on the admissions criteria.</Typography>
      </Box>
      <br/>
      {/* <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Applicants must be prepare the scanned copy (PDF) of the following:</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. PSA Birth Certificate</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. For Senior High School (SHS): Grade 11 Certificate of General Weighted Average (GWA).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. For Alternative Learning System (ALS) completers and Accreditation & Equivalency (A&E) Passers: Certificate of Completion</Typography>
      </Box>
      <br/> */}
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Who may apply?</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* A natural-born Filipino citizen;</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Complied with all academic preparations;</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* With NMAT score of 45 or higher taken within 2 years prior to application deadline;</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* No record of dropping from any medical school;</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* No record of denied admission in PLM College of Medicine;</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* No record of conviction of crime involving moral turpitude; and</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Must have no failing grade in any subject.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Lateral entrees from other medical schools will not be entertained.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Successful applicants are expected to perform excellently and hurdle the rigors in their studies at PLM.</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Application process</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Applicants must complete the following steps to be eligible for ranking. To apply, interested parties are to proceed to the PLM College of Medicine office. Online applications are no longer entertained to provide immediate feedback on the status of the requirements submitted.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. Completely accomplish the application form that includes applicant’s complete name (as shown in birth certificate and school records), present address, mobile number, email address and other details pertinent about the applicant’s background.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. Submit the following documents:<br/>
a. Birth certificate<br/>
b. Official National Medical Aptitude Test (NMAT) result<br/>
c. Official Transcript of Records (for undergraduates: Certification of Grades reflecting all grades up to the 1st semester of the current school year).<br/>
d. Certificate of General Weighted Average from the Registrar/College (for undergraduates: GWA of subjects taken up to the 1st semester of the current school year).<br/>
e. Voter's Certification of Registration with voting history (FOR MANILANS ONLY)<br/>
f. Both parents' latest ITR/Certification of Employment/Copy of Contract</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. After being assessed as qualified applicants, the applicants are to pay the application fee of P3,500.00 as either over-the-counter deposit to the LANDBANK or through online money transfer at the LBP LinkBiz Portal at https://www.lbp-eservices.com/egps/portal/Merchants.jsp under the following account details:<br/>
 
 Account name: Pamantasan ng Lungsod ng Maynila<br/>
 Account number: LBP–2472 1006 56<br/>
 The proof of deposit (original and photocopy) is to be submitted to the PLM College of Medicine.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">For more info, you may contact the College of Medicine:</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Email: <a href="mailto:plmcmadmissions@plm.edu.ph">plmcmadmissions@plm.edu.ph</a></Typography>
      </Box>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CMAT;
