import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeCBA = () => {

  const cba = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
                <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
                <MenuItem
                  onClick={() =>
                    cba.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Business Administration
                </MenuItem>
                <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
                <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
                <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
                <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
                <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
                <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
                <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={cba} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Business Administration</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Business Administration (CBA) offers courses that focus on administering businesses and their general operations.  The program involves decision making skills to organize and strategize business operations and successful entrepreneurs.  Students under this college acquire business philosophy while strengthening their leadership skills, and they have a flexible curriculum that allows them to hound a diversity of careers.</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">In accordance with the Board Resolutions, the evolution of the College of Business Administration (CBA) are as follows:
<br/><br/>Under BOR # 1817 on March 1995, the College of Business and Public Administration was established, and it was restructured into two colleges, the College of Accountancy and Economics (CAE) and the College of Management and Entrepreneurship (CME) in pursuant of BOR # 2467, dated April 2002.
<br/><br/>The University resolved and merged the three colleges, CAE, CME and College of Tourism, Hotel and Information Management (CTHIM) and named into College of Administration, Business and Management (CABM). It was then divided into two colleges, the College of Accountancy and Finance (CAF) and College of Business and Management (CBM) under BOR # 3816 dated June 2015.
<br/><br/>Pursuant to BOR # 4284 dated June 2017, the CA (BOR #3966 March 2016), PRMSG (BOR #4002 May 2016), and CBM were merged and named as College of Business and Government Management (CBGM)
<br/><br/>With BOR # 4716, the University approved and confirmed the restructuring of the College of Business and Government Management (CBGM) into the College of Business and Management (CBM) and the School of Government (SG). 
<br/><br/>By virtue of BOR # 4736, the CBGM was named PLM School of Business, and it was renamed to PLM Business School under the BOR # 4743.

<br/><br/>Pursuant to Board Resolution No. 5189 dated December 8, 2023, the reorganization of the management structure of the Pamantasan Ng Lungsod Ng Maynila has been adopted.

<br/><br/>One of the approved restructures is the division of PLM Business School into three colleges:
<br/>a.        College of Accountancy (CA)
<br/>b.        College of Tourism and Hospitality Management (CTHM)
<br/>c.        College of Business Administration (CBA)

<br/><br/>Each of the college has their respective academic disciplines, namely:
<br/>a.        CA: Accountancy
<br/>b.        CTHM: Tourism and Hospitality Management
<br/>c.        CBA: Entrepreneurship, Financial Management, Human Resource Management, Operations Management, Marketing Management, Entrepreneurship, Real Estate Management

        </Typography>
      </Box>      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        To be an empowered leader in business and management thought and trained through comprehensive creation, competitive business and service professionals equipped with knowledge, skills, values, and extraordinary experiences in the greater glory of God.
        </Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        To produce value for students, business, and society through activities that lead to career success and the advancement of knowledge and by providing its learners the opportunities to distinguish their abilities to apply requisites and philosophies specific to business and to transform them into service-oriented professional with high spiritual, and ethical that contributes to the society.
        </Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Guided by the college's vision and mission, we commit ourselves to:
        <br/>To provide excellent undergraduate and graduate degree programs that will prepare students to become successful business professionals in an increasingly various and interrelated workplace. 
        <br/>To conduct and disseminate research that improves the practice of business, providing practical learning opportunities for students, and reinforcement of strong academic reputation for purposes of student recruitment and placement.
        <br/>To develop a strong, inclusive family values, a diverse and well-qualified number of students, a faculty committed to high-quality research and teaching, dedicated support staff, and strong support from alumni and the business partners. 
        <br/>To produce quality and globally competitive business professionals and entrepreneurs who are equipped with skills, knowledge and values necessary for professionals.
</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Bachelor of Science in Business Administration major in Marketing Management (BSBA-MM)

<br/>The BSBA in Marketing Management is a program designed to equip students with the knowledge and skills effective for marketing and sales strategies; how a company determines what product or service to sell, how customers and markets are delineated into target demographics and the methods of reaching them.

<br/><br/>Career and Profession
<br/>•        Marketing Executive
<br/>•        Sales Account Executive 
<br/>•        Market Researcher
<br/>•        Digital Marketing Executive
<br/>•        Market Analyst
<br/>•        Brand Executive
<br/>•        Marketing Communication Officer

<br/><br/>Bachelor of Science in Business Administration Major in Business Economics (BSBA-BE)

<br/>•        BSBA Major in Business Economics is a program design to provide students with a strong foundation and understanding of the field of economics, covering local and global economic conditions, as well as the methods and application of economic analysis in business decision making and overall operations. 
<br/>•        The program focuses on the relationship between business operations and the economy. It integrates economic principles and strategies into standard business practices so that the student can have both the micro view of how the business can acquire capital, generate profit and efficiently produce goods and services, as well as the macro view of how external factors such as government regulation can affect, and should influence business decisions.
<br/>•        It is an interdisciplinary and problem-focused program comprising three integrated elements: core business and management education courses, business administration core and major courses.
<br/><br/>Career and Profession
<br/>•        Lawyers
<br/>•        Prosecutors
<br/>•        Vice Presidents in the Corporate Industries
<br/>•        CEO of a franchising company
<br/>•        Professors
<br/>•        Senior Corporate Analyst
<br/>•        Financial Analyst
<br/>•        Business Development Managers
<br/>•        Stockbrokers
<br/>•        Operations Managers
<br/>•        Finance Managers
<br/>•        Businessmen
<br/>•        Researchers
<br/>•        Comptroller
<br/>•        Economists
<br/><br/>Bachelor of Science in Business Administration major in Financial Management (BSBA-FM)

<br/>Bachelor of Science in Business Administration, Major in Financial Management is a program that provides students with a strong foundation on theories, principles, and concepts that equip them with relevant technical and analytical skills necessary in financial decision-making, cognizant of a dynamic domestic and global business environment, and mindful of their role in nation-building. The students’ terminal outputs are research undertakings that are geared toward both application of learned concepts and/or theory development. 

<br/><br/>The program is anchored on its three-item agenda: (1) a competency-based curriculum; (2) a mix of faculty who have relevant industry practice and years of teaching experience; and (3) student-centered co-curricular and extra-curricular activities.
<br/><br/>Career and Profession
<br/>•        Financial Analyst Investment 
<br/>•        Researcher 
<br/>•        FOREX Specialist 
<br/>•        Fund Manager 
<br/>•        Trust/Treasury Assistant 
<br/>•        Equity Analyst/Bond Analyst 
<br/>•        Stocks/Bonds/Derivatives Trader or Broker 
<br/>•        Bank Management Trainee 
<br/>•        Financial Consultant 
<br/>•        Finance Supervisor 
<br/>•        Risk Management Associate


<br/><br/>Bachelor of Science in Business Administration major in Human Resource Management (BSBA - HRM)
<br/>The Bachelor of Science in Business Administration, major in Human Resource Management is a program that develops the necessary skills and competencies to enable the effective management of an organization’s human capital. Students are expected to be capable of performing human resources functions and to gain an understanding in enabling personnel to pursue organizational objectives.

<br/><br/>The program prepares the graduate for a career in the Human Resources Department of any organization, handling the many diverse human capital requirements of that organization.
<br/><br/>Career and Profession
<br/>•        Human Resource Generalist
<br/>•        Recruitment Specialist
<br/>•        Compensation and Benefits Specialist
<br/>•        Training and Development Specialist

<br/><br/>Bachelor of Science in Business Administration major in Operations Management (BSBA - OM)

<br/>The Bachelor of Science in Business Administration, major in Operations Management is program that studies the interrelationships among the different functional areas of a business and examine how effective orchestration of these different components of business operations can lead to organizational success.

<br/><br/>The program prepares students to manage the operations of manufacturing and service enterprises with a focus on the need to effectively and efficiently produce and deliver products within budget and on time. It develops practical skills for students to become a business efficiency expert in a wide range of industries.

<br/><br/>Career and Profession
<br/>•        Career and Profession:
<br/>•        Operations/Production Management Specialist
<br/>•        Inventory Specialist
<br/>•        Logistics Management Personnel
<br/>•        Project Specialist
<br/>•        Entrepreneur


<br/><br/>Bachelor of Science in Entrepreneurship (BS Entrepreneurship)

<br/>The Bachelor of Science in Entrepreneurship is a program which focuses on the education of students in seeing opportunities, seizing them and creating values for themselves and others in a sustainable manner. Furthermore, it promotes entrepreneurship opportunities for the student, by providing them knowledge and guide them to create their own small business and become an efficient binding force of a nationwide chain of entrepreneurship and encourage students in the advocacy of the development in business and industry. 

<br/><br/>Career and Profession
<br/>•        Entrepreneur 
<br/>•        Business consultant 
<br/>•        Financial Adviser 
<br/>•        Business Analyst 
<br/>•        Management consultant

<br/><br/>Bachelor of Science in Real Estate Management (BS-REM)

<br/>The Bachelor of Science in Real Estate Management (BS-REM) program aims to produce technically competent, responsible, and respected real estate professionals. The BS-REM curriculum includes general education courses, real estate management fundamentals, and business management theories. The course focuses on understanding real estate principles, knowledge, and practice in brokerage, assessment, and consulting. Completing the BS-REM course allows you to become a PRC duly licensed Real Estate Broker, Appraiser, or Consultant. Financial abundance awaits duly licensed Real Estate Professional practitioners.

<br/><br/>Career and Profession
<br/>•        Real Estate Broker
<br/>•        Real Estate Appraiser
<br/>•        Real Estate Consultant        
</Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
•        Top Outstanding Economic Student January 2021 and April 2022
<br/>•        Best Position Paper, ASEAN-China Young Leaders’ Summit by the ASEAN Foundation and Peking University, September 2021
<br/>•        Champion, E-Quiz-librium (Economic Quiz Bee) by the University of the Philippines – Association of Economics Students (UP-AES), March 2021
<br/>•        Best Speaker, Motions in Motion 2018: National Economics Debate Tournament, 2018 
<br/>•        Selected as Youth PH Delegate for the World Conference on Tobacco or Health at Cape Town, South Africa by Action for Economic Reforms, 2018
<br/>•        Over-all Champion, Motions in Motion 2018: National Economics Debate Tournament, 2018
<br/>•        1st Place, Ms. Junior Philippine Economics Society Ecolympics, 2017
<br/>•        Top 10 Outstanding Junior Philippine Economics Society Students, 2016
<br/>•        Winner, PR Plan Competition, Public Relations Society of the Philippines 2019
<br/>•        Finalist, Comguild Competition 2019
<br/>•        Best Video Presentation, 2nd place Interuniversity competition, Most Innovative and Creative Design, San Beda University 
<br/>•        Go Green: From Garbage to Garments, 19 September 2018, MVP Gymn, SBU
<br/>•        JES student, 3rd place Dance Supremacy 2024 
<br/>•        JES Students, 3rd place BIDA Competition
<br/>•        Passed the first PRC Real Estate Appraiser licensure examination in 2022
<br/>•        Topnotcher #10 in the Real Estate Brokers Licensure Examination (2023)
<br/>•        Topnotcher #10 in the Real Estate Appraisers Licensure Examination in 2023
<br/>•        Topnotcher #9 in the 2024 Real Estate Brokers Examination
      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeCBA;
