import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import dean1 from './img/dean_gsp.jpg';
import dean2 from './img/dean_casbe.jpg';
import dean3 from './img/dean_educ.jpg';
import dean4 from './img/dean_ceng.jpg';
import dean5 from './img/dean_istm.jpg';
import dean6 from './img/dean_chass.jpg';
import dean7 from './img/dean_lawgrad.jpg';
import dean8 from './img/dean_nursing.jpg';
import dean9 from './img/dean_science.jpg';
import dean10 from './img/dean_ba.jpg';
import dean11 from './img/dean_pa.jpg';
import dean12 from './img/dean_acct.jpg';
import { useRef } from 'react';

const Dean = () => {
  const dean = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Deans</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Vice President</MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem
          onClick={() =>
            dean.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Deans
        </MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>
      </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={dean} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      {/* <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Deans</Typography>
      </Box>
      <br/> */}
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={dean1} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean2} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean3} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean4} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={dean5} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean6} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean7} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean8} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={dean9} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean10} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean11} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={dean12} width="25%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default Dean;
