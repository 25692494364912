import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';

const ContactCollege = () => {

  const contactCollege = useRef(document.createElement("p"));

  const handleContact = () => {
    window.location.href="./Contact";
  };

  const handleContactOffice = () => {
    window.location.href="./ContactOffice";
  };

  const handleContactExecutive = () => {
    window.location.href="./ContactExecutive";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt=""/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Contact Information</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
                {/* <MenuItem onClick={handleContact}>Contact PLM</MenuItem> */}
                <MenuItem
                  onClick={() =>
                    contactCollege.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  PLM Colleges
                </MenuItem>
                <MenuItem onClick={handleContactOffice}>PLM Offices</MenuItem>
                <MenuItem onClick={handleContactExecutive}>PLM Executive Offices</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={contactCollege} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
          <Typography>College of Architecture and Urban Planning</Typography>
          <Typography sx={{paddingRight: '5%'}}>Email: caupgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Engineering and Technology</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: cet_group@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Education</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: cedgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Humanities, Arts and Social Science</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: chassgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Nursing</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: cngroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Physical Therapy</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: cptgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Science</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: csgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Law</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: clgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>College of Medicine</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: cmgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>PLM Business School</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: plmbsgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Graduate School of Law</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: gslgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>School of Public Health</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: sophgroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>School of Government</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: soggroup@plm.edu.ph</Typography>
      </Box>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default ContactCollege;
