import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import UP1 from './img/up1.jpg';
import UP2 from './img/up2.jpg';
import UP3 from './img/up3.jpg';
import UP4 from './img/up4.jpg';
import VPASRE1 from './img/vpasre1.jpg';
import VPASRE2 from './img/vpasre2.jpg';
import VPASRE3 from './img/vpasre3.jpg';
import VPASRE4 from './img/vpasre4.jpg';
import VPASRE5 from './img/vpasre5.jpg';
import VPASRE6 from './img/vpasre6.jpg';
import VPASRE7 from './img/vpasre7.jpg';
import VPA1 from './img/vpaa1.jpg';
import VPA2 from './img/vpaa2.jpg';
import VPA3 from './img/vpaa3.jpg';
import VPA4 from './img/vpaa4.jpg';
import VPA5 from './img/vpaa5.jpg';
import VPA6 from './img/vpaa6.jpg';
import VPA7 from './img/vpaa7.jpg';
import VPF1 from './img/vpf1.jpg';
import VPF2 from './img/vpf2.jpg';
import VPF3 from './img/vpf3.jpg';
import VPF4 from './img/vpf4.jpg';
import VPF5 from './img/vpf5.jpg';

import { useRef } from 'react';

const DirectorChief = () => {
  const director = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Directors and Chiefs</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Vice President</MenuItem>
        <MenuItem
          onClick={() =>
            director.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Directors and Chiefs
        </MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={director} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      {/* <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Directors and Chiefs</Typography>
      </Box> */}
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Office of the University President</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={UP1} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={UP2} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={UP3} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={UP4} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Vice President for Academic Services, Research & Extension</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={VPASRE1} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPASRE2} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPASRE3} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPASRE4} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={VPASRE5} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPASRE6} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPASRE7} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <Typography variant="h6">MERRY M. CLAMOR, PH.D Acting Director, University Research Center</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Vice President for Administration</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={VPA1} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPA2} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPA3} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPA4} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={VPA5} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPA6} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPA7} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Vice President for Finance</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={VPF1} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPF2} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPF3} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={VPF4} width="25%" alt=""/>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={VPF5} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default DirectorChief;
