import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {  Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';

const columns: GridColDef<(typeof rows)[number]>[] = [
  {
    field: 'college',
    headerName: 'College',
    width: 500,
    editable: true,
  },
  {
    field: 'Program',
    headerName: 'Program Offering',
    width: 1200,
    editable: true,
  },
];

const rows = [
  { id: 1, college: 'College of Business Administration (CBA)', Program: 'Master in Business Administration, Doctor in Business Administration'},
  { id: 2, college: 'College of Education (CED)', Program: 'Certificate in Professional Education, Master of Arts in Education major in Social Sciences, Master of Arts in Education major in Biological Sciences, Master of Arts in Education major in Physics, Master of Arts in Education major in Chemistry, Master of Science in Mathematics Education, Master of Arts in Special Education major in Developmental Delays, Master of Arts in Education major in Educational Management and Leadership, Doctor of Education major in Educational Management and Leadership, Doctor of Education in Educational Administration' },
  { id: 3, college: 'College of Engineering (CE)', Program: 'Master of Engineering with Specialization in Computer Engineering, Master of Engineering with Specialization in Structural Engineering, Master of Engineering Management with Specialization in Contruction Management, MS Management Engineering' },
  { id: 4, college: 'College of Information System & Technology Management (CISTM)', Program: 'Master in Information Technology' },
  { id: 5, college: 'College of Humanities, Arts and Social Sciences (CHASS)', Program: 'Master of Social Work, MA Communication Management' },
  { id: 6, college: 'College of Nursing (CN)', Program: 'MA Nursing' },
  { id: 7, college: 'College of Physical Therapy (CPT)', Program: 'Master of Science in Physical Therapy' },
  { id: 8, college: 'College of Science', Program: 'Master of Arts in Psychology (Major in Clinical Psychology), Master of Arts in Psychology (Major in Industrial Psychology)' },
  { id: 9, college: 'College of Law', Program: 'Master of Laws' },
  { id:10, college: 'College of Public Administration', Program: 'Master in Government Management, Doctor of Government Management'},
];

const Graduate = () => {

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Office for Graduates and Professional Studies</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
      </Box>
      <Box sx={{width: "100%", paddingLeft: 'px'}}>
        <Box sx={{ height: 'auto', width: '100%'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
      />
    </Box>
</Box>
</Box>
<Footer/>
</div>

  );
}

export default Graduate;
