// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container Display Styles (for Image & Video) */

.rcb-media-display-image-container, .rcb-media-display-video-container {
	margin-top: 8px;
	margin-right: 16px;
	border-radius: 22px;
	padding: 16px 16px;
	width: -moz-fit-content;
	width: fit-content;
}

.rcb-media-display-offset {
	margin-right: 50px !important;
}

/* Image Display */

.rcb-media-display-image {
	width: 100%;
	height: auto;
	border-radius: 22px;
	object-fit: cover;
}

/* Video Display */

.rcb-media-display-video {
	width: 100%;
	height: auto;
	border-radius: 22px;
	background-color: black;
}

/* Audio Display */

.rcb-media-display-audio {
	margin-top: 8px;
	margin-right: 16px;
	width: 100%;
	height: auto;
	border-radius: 22px;
}

/* Media Display Entry Animations */

.rcb-media-entry {
	animation: media-entry 0.3s ease-in backwards;
}

@keyframes media-entry {
	0% {
		transform: translate(100%, 50%) scale(0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatBotBody/MediaDisplay/MediaDisplay.css"],"names":[],"mappings":"AAAA,iDAAiD;;AAEjD;CACC,eAAe;CACf,kBAAkB;CAClB,mBAAmB;CACnB,kBAAkB;CAClB,uBAAkB;CAAlB,kBAAkB;AACnB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA,kBAAkB;;AAElB;CACC,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA,kBAAkB;;AAElB;CACC,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA,kBAAkB;;AAElB;CACC,eAAe;CACf,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,mBAAmB;AACpB;;AAEA,mCAAmC;;AAEnC;CACC,6CAA6C;AAC9C;;AAEA;CACC;EACC,wCAAwC;EACxC,UAAU;CACX;CACA;EACC,mCAAmC;EACnC,UAAU;CACX;AACD","sourcesContent":["/* Container Display Styles (for Image & Video) */\r\n\r\n.rcb-media-display-image-container, .rcb-media-display-video-container {\r\n\tmargin-top: 8px;\r\n\tmargin-right: 16px;\r\n\tborder-radius: 22px;\r\n\tpadding: 16px 16px;\r\n\twidth: fit-content;\r\n}\r\n\r\n.rcb-media-display-offset {\r\n\tmargin-right: 50px !important;\r\n}\r\n\r\n/* Image Display */\r\n\r\n.rcb-media-display-image {\r\n\twidth: 100%;\r\n\theight: auto;\r\n\tborder-radius: 22px;\r\n\tobject-fit: cover;\r\n}\r\n\r\n/* Video Display */\r\n\r\n.rcb-media-display-video {\r\n\twidth: 100%;\r\n\theight: auto;\r\n\tborder-radius: 22px;\r\n\tbackground-color: black;\r\n}\r\n\r\n/* Audio Display */\r\n\r\n.rcb-media-display-audio {\r\n\tmargin-top: 8px;\r\n\tmargin-right: 16px;\r\n\twidth: 100%;\r\n\theight: auto;\r\n\tborder-radius: 22px;\r\n}\r\n\r\n/* Media Display Entry Animations */\r\n\r\n.rcb-media-entry {\r\n\tanimation: media-entry 0.3s ease-in backwards;\r\n}\r\n\r\n@keyframes media-entry {\r\n\t0% {\r\n\t\ttransform: translate(100%, 50%) scale(0);\r\n\t\topacity: 0;\r\n\t}\r\n\t100% {\r\n\t\ttransform: translate(0, 0) scale(1);\r\n\t\topacity: 1;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
