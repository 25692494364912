import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeCASBE = () => {

  const casbe = useRef(document.createElement("p"));

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper sx={{padding: '8px'}}>
              <MenuList>
              <MenuItem
                  onClick={() =>
                    casbe.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Architecture
                </MenuItem>
                <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
                <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
                <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
                <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
                <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
                <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
                <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts & Social Science</MenuItem>
                <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={casbe} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Architecture and Sustainable Built Environments (CASBE)</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The College of Architecture and Sustainable Built Environment is an academic unit of PLM that provides quality education for the next generation of architecture and construction professionals to become leaders in the sustainable design, construction, and management of the built environment. It offers a comprehensive approach that integrates traditional architecture with a focus on environmental and social responsibility.</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The Bachelor of Science in Architecture program was first offered at the university in 1987. The basic objective of opening this unique 5-year discipline was to extend equal opportunity to economically challenged but deserving students of Manila. This, along with Fine Arts, was initially attached to the College of Engineering and Technology, among the largest colleges on the campus even until today. 
          <br/><br/>The program was eventually given its own academic unit, the College of Architecture and Urban Planning (CAUP), and was officially recognized as a separate college on January 10, 2001 by virtue of BOR Resolution No. 2272. Together with the Manila local government's vision to transform an urban decay city into a vibrant, world-class urban tourist destination; this thrust on urban renewal paved the way to institutionalize sustainable programs by way of a permanent home centered on urban planning and design.
          <br/><br/>In light of the industry shift to sustainable design and construction, the college was renamed as the College of Architecture and Sustainable Built Environments in January 2024 to align its goals and objectives towards sustainable development and the flexibility to offer new programs that would meet them. For many academic years this College prides itself of reaping numerous honors and citations for PLM, both in local and national arena. These achievements were a result of a strong and continuing partnership built around a team spirit nurtured by an inspired studentry and a supportive institution.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        To become a partner of choice as an active provider of competent industry players in today’s built-environment market and be a catalyst for sustainable change in the City of Manila and beyond.
        </Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Create an environment mutually beneficial to students and the institution by providing opportunities of cooperation while in constant adherence to excellence in professionalism, creativity, team work, and leadership to foster their awareness on professional responsibilities towards sustainability, the socio-cultural strata, environmental values, built heritage conservation, technical research and collaboration with allied-professions in developing design leadership.
        </Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Guided by the college's vision and mission, we commit ourselves to:<br/>
        1. The College is committed to educate and nurture competent future architects and built environment professionals as partners to nation building in producing excellence- oriented and morally upright students and future professionals prepared for leadership role in the industry.
        <br/>2. The College is dedicated to instill the values of critical thinking and academic excellence while training future professionals to understand sustainable practices  anchored to protect the built- environment, for the common welfare, and for benefit of the  City of Manila.
        <br/>3. To imbue upon graduates a direction towards a genuine socially- responsive and highly- innovative mindset that are well-grounded on the technical aspects, and updated on the technology and prevailing conditions
        <br/>4. To provide in-depth understanding of the profession and the role of built environment professionals in the society and nation building.
        </Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Bachelor of Science in Architecture
        <br/>The Bachelor of Science in Architecture is a five (5)-year undergraduate program designed to equip students with a comprehensive understanding of architectural theories, design principles, building technologies, and historical contexts. Through courses in design, visualization, structures, and sustainability, this program prepares graduates for a career in architecture and related fields in the construction industry.

        <br/><br/>Careers for professionals:
        <br/>a. Architectural Design, Pre-design services for architecture
        <br/>b. Housing
        <br/>c. Physical Planning
        <br/>d. Urban Design
        <br/>e. Community Architecture
        <br/>f. Facility Planning
        <br/>g. Construction Technology 
        <br/>h. Construction Management 
        <br/>i. Project Management
        <br/>j. Building Administration and Maintenance
        <br/>k. Real Estate Development
        <br/>I. Architectural Education 
        <br/>m. Research and Development
        <br/>n. Restoration/Conservation
        <br/>o. Design-build Services

        <br/><br/>Careers for graduates:
        <br/>a. Architectural drafting
        <br/>b. CADD operator
        <br/>c. Project Coordinator
        <br/>d. Project Supervisor/Inspector
        <br/>e. Project estimator
        <br/>f. Rendering (manual and electronic)

        </Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Recent Licensure Examination for Architects Topnotchers:
          <br/>1. Mikko Joyce Bustamante - Top 6 June 2022
          <br/>2. Bea Denise Santos  - Top 10 January 2022 
          <br/>3. Michael Raye Tadeo - Top 4 January 2018
          <br/>4. Aldous Olbes - Top 2 June 2017
          <br/>5. Alnie Khayzer P. Hayudini - Top 10 January 2016
          <br/>6. Rodrick Paul V. Mendoza, Jr. - Top 1 June 2014 
        </Typography>
      </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeCASBE;
