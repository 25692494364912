// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Options Container */

.rcb-options-container {
	padding-top: 12px;
	margin-left: 16px;
	max-width: 70%;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.rcb-options-offset {
	margin-left: 50px !important;
}

/* Options */
  
.rcb-options {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	border-radius: 20px;
	font-size: 14px;
	border-width: 0.5px;
	border-style: solid;
	cursor: pointer;
	transition: background-color 0.3s ease;
	animation: options-entry 0.5s ease-out;
	overflow: hidden;
}

@keyframes options-entry {
	0% {
	  transform: scale(0);
	  opacity: 0;
	}
	100% {
	  transform: scale(1);
	  opacity: 1;
	}
}

.rcb-options:hover {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
}`, "",{"version":3,"sources":["webpack://./src/components/ChatBotBody/UserOptions/UserOptions.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;CACC,iBAAiB;CACjB,iBAAiB;CACjB,cAAc;CACd,aAAa;CACb,eAAe;CACf,SAAS;AACV;;AAEA;CACC,4BAA4B;AAC7B;;AAEA,YAAY;;AAEZ;CACC,oBAAoB;CACpB,mBAAmB;CACnB,uBAAuB;CACvB,kBAAkB;CAClB,mBAAmB;CACnB,eAAe;CACf,mBAAmB;CACnB,mBAAmB;CACnB,eAAe;CACf,sCAAsC;CACtC,sCAAsC;CACtC,gBAAgB;AACjB;;AAEA;CACC;GACE,mBAAmB;GACnB,UAAU;CACZ;CACA;GACE,mBAAmB;GACnB,UAAU;CACZ;AACD;;AAEA;CACC,sCAAsC;AACvC","sourcesContent":["/* Options Container */\r\n\r\n.rcb-options-container {\r\n\tpadding-top: 12px;\r\n\tmargin-left: 16px;\r\n\tmax-width: 70%;\r\n\tdisplay: flex;\r\n\tflex-wrap: wrap;\r\n\tgap: 10px;\r\n}\r\n\r\n.rcb-options-offset {\r\n\tmargin-left: 50px !important;\r\n}\r\n\r\n/* Options */\r\n  \r\n.rcb-options {\r\n\tdisplay: inline-flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\tpadding: 10px 20px;\r\n\tborder-radius: 20px;\r\n\tfont-size: 14px;\r\n\tborder-width: 0.5px;\r\n\tborder-style: solid;\r\n\tcursor: pointer;\r\n\ttransition: background-color 0.3s ease;\r\n\tanimation: options-entry 0.5s ease-out;\r\n\toverflow: hidden;\r\n}\r\n\r\n@keyframes options-entry {\r\n\t0% {\r\n\t  transform: scale(0);\r\n\t  opacity: 0;\r\n\t}\r\n\t100% {\r\n\t  transform: scale(1);\r\n\t  opacity: 1;\r\n\t}\r\n}\r\n\r\n.rcb-options:hover {\r\n\tbox-shadow: 0 0 5px rgba(0, 0, 0, 0.2); \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
