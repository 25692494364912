import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import past1 from './img/benitoreyes.png';
import past2 from './img/consueloblanco.png';
import past3 from './img/ramonbagatsing.png';
import past4 from './img/josevillanueva.png';
import past5 from './img/benjamintayabas.png';
import past6 from './img/virselydelacruz.png';
import past9 from './img/adeltamano.png';
import past11 from './img/artemiotuquero.png';
import past12 from './img/leonoradejesus.png';
import past13 from './img/emmanuelleyco.jpg';
import { useRef } from 'react';

const PastPresident = () => {
  const past = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Past University Presidents</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Vice President</MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem
          onClick={() =>
            past.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Past University Presidents
        </MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={past} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      {/* <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Past University Presidents</Typography>
      </Box> */}
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Get to know the men and women whose vision and leadership shaped PLM to become the renowned and respected university that it is today.</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={past1} width="20%" height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={past2} width="20%"  height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={past3} width="20%" height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={past4} width="20%" height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={past5} width="20%" height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>DR. BENITO REYES</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>DR. CONSUELO S. BLANCO</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>HON. MAYOR RAMON BAGATSING</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>DR. JOSE D. VILLANUEVA</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>DR. BENJAMIN D. TAYABAS</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Feb. 23, 1967 to Jun 23, 1972</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Dec 21, 1972 to May 31, 1978</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>June 1, 1978 to Oct 27, 1982</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jan 14, 1983 to Jun 30, 1989</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jul 2, 1989 to Jun 24, 1996</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Feb 23, 2000 to Feb 22, 2006</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jun 2, 2006 to Aug 1, 2007</Typography>
      </Box>

      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={past6} width="20%"  height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={past9} width="20%" height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={past11} width="20%"  height="50%"  alt="" style={{ transform: 'scaleX(-1)', border: "solid 3px red", margin: "1px" }}/>
        <img src={past12} width="20%" height="50%" alt=""  style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={past13} width="20%"  height="50%" alt="" style={{ transform: 'scaleX(-1)', border: "solid 3px red", margin: "1px" }}/>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>DR. VIRSELY M. DELA CRUZ</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>ATTY. ADEL A. TAMANO</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>JUSTICE ARTEMIO G. TUQUERO</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>DR. MA. LEONORA V. DE JESUS</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>PROF. EMMANUEL A. LEYCO</Typography>
      </Box>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jun 25, 1996 to April 30, 1999</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Aug 9, 2007 to Nov 31, 2009</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jul 3, 2013 to Sep 17, 2014</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Sep 2014 to Jun 2019</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>July 1, 2019 to October 15, 2023</Typography>
      </Box>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default PastPresident;
