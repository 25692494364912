import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeNursing = () => {

  const nursing = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
              <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
              <MenuItem
                  onClick={() =>
                    nursing.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Nursing
                </MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={nursing} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Nursing</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Nursing at PLM was established in 1969, in response to a study highlighting the need for community-oriented healthcare in Manila. Since its inception, the college has admitted numerous nursing scholars and has consistently produced top performers in the nurse licensure examinations. The college's alumni have achieved significant recognition, with many placing in the top ranks of the exams over the years.
        <br/>The College of Nursing operates with a clear organizational structure and defined roles for faculty and students. Policies cover various aspects, including faculty responsibilities, student conduct in classrooms and clinical areas, and general operational procedures. There are committees dedicated to continuing education, faculty research, student development, community service, and library resources.
        <br/>The College of Nursing at PLM is recognized as one of the top nursing schools in the Philippines, a distinction earned through the dedication and hard work of its faculty and students. The college's consistent excellence in nurse licensure exams and its contribution to the field of nursing education have cemented its reputation both locally and internationally.
        <br/>Every year, the College of Nursing admits top academic performers motivated to pursue the noble profession of nursing. Students receive clinical training at prestigious hospitals and health agencies, ensuring they are well-prepared for their careers. The college's leadership, composed of distinguished individuals, has played a crucial role in maintaining its high standards and guiding it to its current preeminent position.

        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        In 1967, a study entitled "Manila - Its Needs and Resources" indicated the need to open a community-oriented college, based on its finding that despite the physical proximity to the people of the 41 Manila Health Centers, there were less community people who seek help / healthcare from them.  This study confirmed the then and now current thinking of health care experts that in order to meet the health needs of the people, it is essential that health workers must go where the people are - to their homes in the community. This led to the conception of the College of Nursing at Pamantasan ng Lungsod ng Maynila (PLM).
        <br/>When PLM opened on July 17, 1967 many of the enrollees in the 2-year Associate Arts course were prospective nursing students.  In the summer of 1969, the PLM - College of Nursing was established by the President Dr. Benito F. Reyes, and appointed Ms. Valentina Patacsil as the College Secretary.  Under her guidance, twenty-nine (29) students were selected to be the first nursing scholars of the University.  On July 1, 1969, Mrs. Mary Vita B. Jackson was appointed Dean of the College of Nursing - PLM.  Four (4) years later, 27 of those scholars passed the Board Examination given in April 1972 with one of them placing 9th.  Since then more PLM - College of Nursing graduates have distinguished themselves as perennial placers in the nurse licensure examination and a large number of its undergraduate students have reaped numerous honors for the College in various intercollegiate competitions, both academic and nonacademic.  The products of the PLM - College of Nursing have also proven to be topflight and competitive nurse practitioners both in the Philippines and abroad.
        </Typography>
      </Box>      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        A leader in nursing education in the ASEAN region producing globally competitive nurses with excellence, integrity and social responsibility.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        1.        To be recognized by Philippines and ASEAN Academic accrediting agencies as premier nursing school known for its quality education, research and extension service.

        <br/>2.        To produce nurse leaders in the fields of clinical practice, research, academe and entrepreneurship with a competitive advantage for employment opportunities.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        In consonance with the national program of primary health care and the global objective of health for all.  PLM has developed the College of Nursing with a curriculum that primarily aims to prepare a fully functioning nurse who has:
        <br/>1.        Developed a sensitive awareness to the changing health needs of the society as well as commitment to the alleviation of problems and to the holistic development of the Filipino citizen.
        <br/>2.        Acquired the necessary knowledge, skills and caring attitude geared towards the promotion of health, prevention of disease, maintenance and restoration of wellness, as well as facilitation of a peaceful death.
        <br/>3.        Developed a positive attitude towards research to broaden the perspective in the delivery of quality health care.
        <br/>4.        Acquired and can perform beginning professional competencies in the practice of the profession as s/he demonstrates the following key areas of responsibility: 
        <br/>a.        Safe and quality nursing care
        <br/>b.        Management of resources and environment
        <br/>c.        Health education
        <br/>d.        Legal responsibility
        <br/>e.        Ethico-moral responsibility
        <br/>f.        Personal and professional development
        <br/>g.        Quality improvement
        <br/>h.        Research
        <br/>i.        Record management 
        <br/>j.        Communication
        <br/>k.        Collaboration and teamwork
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
The BSN is a Four-year program consisting of general education and professional courses. Professional courses are threaded through from the first year through the fourth year with emphasis on the nursing concepts with corresponding Related Learning Experience (RLE). The BSN program provides an intensive nursing practicum that will refine further the nursing competencies to ensure achievement of the BSN program outcomes required of an entry level nurse.
<br/><br/>Careers and Professions:
<br/>Clinical Practice:
<br/>•        Staff Nurse: Providing direct patient care in hospitals, clinics, and other healthcare facilities.
<br/>•        Community Health Nurse: Working in public health to promote community health and wellness.
<br/>•        School Nurse: Managing health services in educational institutions.
<br/>•        Occupational Health Nurse: Focusing on health and safety in workplace settings.
<br/>•        Military Nurse: Providing healthcare services in military settings.
<br/><br/>Leadership and Management:
<br/>•        Nurse Administrator: Managing nursing staff and operations in healthcare facilities.
<br/>•        Nurse Manager: Overseeing specific departments or units within a healthcare facility.
<br/>•        Director of Nursing: Leading the nursing department and coordinating care across various units.
<br/><br/>Education and Research:
<br/>•        Nurse Educator: Teaching and training future nurses in academic and clinical settings.
<br/>•        Nurse Researcher: Conducting research to advance nursing practice and improve patient outcomes.
<br/><br/>Entrepreneurship:
<br/>•        Private Practitioner: Running a private nursing practice or consultancy.
<br/>•        Healthcare Business Owner: Managing a healthcare-related business, such as a home healthcare agency or wellness center.
<br/><br/>Specialized Nursing Fields:
<br/>•        Pediatric Nurse: Caring for infants, children, and adolescents.
<br/>•        Geriatric Nurse: Focusing on the health and care of elderly patients.
<br/>•        Oncology Nurse: Specializing in cancer care and treatment.
<br/>•        Psychiatric Nurse: Providing mental health services to patients with psychiatric conditions.
<br/>•        Emergency Room Nurse: Offering care in emergency and trauma situations.
<br/>•        Critical Care Nurse: Working in intensive care units to provide care for critically ill patients.


<br/><br/>Master of Arts in Nursing

<br/>The Master of Arts in Nursing (MAN) program is a 36-unit curriculum designed to advance nursing professionals' expertise. It begins with core courses in research designs, statistical methods, and educational foundations. Specialization courses include promotion and maintenance of wellness, restoration of wellness, an intensive clinical practicum, nursing theory, health services administration, and clinical teaching.

<br/>Electives cover legal and ethical issues, behavioral concepts, socio-cultural health factors, public health education, advanced health administration, comparative nursing practices, pharmacodynamics, and professional development. The program concludes with thesis writing, where students conduct research and prepare a thesis with an oral defense.

<br/>This program equips nurses for leadership roles in clinical practice, education, and administration.

        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Nursing at Pamantasan ng Lungsod ng Maynila (PLM) has received numerous awards and recognitions over the years, both through the achievements of its students and the overall reputation of the college. Here are some key highlights:
        <br/><br/>1.        Consistent Performance in Licensure Examinations:
        <br/>*        PLM College of Nursing graduates have consistently ranked among the top performers in the Philippine Nurse Licensure Examination. This includes numerous students placing in the top ten, with notable achievements such as:
        <br/>1st Place by Rosalinda Fernandez (1974), Teresita Cueto (1975), Emma Advincula (1977), Lea Liwanag (1983), Zandra Mae Z. Bongco (2007), Nikko Martel V. Dio (2013), Elijah C. Legaspi (2014), Suha C. Hassan Magdy Mohammed Ibrahim (2015) and Aristotle C. Castronuevo (2023)
        <br/>High rankings consistently from 1972 to recent years, highlighting the college's enduring excellence.
        <br/><br/>2.        National and International Recognition:
        <br/>*        PLM College of Nursing is recognized as one of the top nursing schools in the Philippines and has received accolades for its quality education, research, and extension services.
        <br/>*        The college's commitment to academic excellence, integrity, and social responsibility has contributed to its reputation as a leader in nursing education within the ASEAN region.
        <br/><br/>3.        Leadership and Faculty Excellence:
        <br/>*        The college has been guided by a distinguished roster of deans and faculty members who have contributed to its success and reputation.
        <br/>*        The continuous effort in curriculum assessment and revision ensures that the education provided remains relevant and of high quality.

        </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeNursing;
