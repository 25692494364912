import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';


const ContactOffice = () => {

  const contactOffice = useRef(document.createElement("p"));

  const handleContact = () => {
    window.location.href="./Contact";
  };

  const handleContactCollege = () => {
    window.location.href="./ContactCollege";
  };

  const handleContactExecutive = () => {
    window.location.href="./ContactExecutive";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Contact Information</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
                {/* <MenuItem onClick={handleContact}>Contact PLM</MenuItem> */}
                <MenuItem onClick={handleContactCollege}>PLM Colleges</MenuItem>
                <MenuItem
                  onClick={() =>
                    contactOffice.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  PLM Offices
                </MenuItem>
                <MenuItem onClick={handleContactExecutive}>PLM Executive Offices</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={contactOffice} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of the Registrar</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: registrar@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Admission</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: Admission_office@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Cash Office/Treasury</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: to@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Budget Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: budget@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Internal Audit Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: iao@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Information and Communications Technology Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: ithelp@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of Guidance and Testing Services</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: ogts@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of Student and Development Services</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: osds@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>University Library</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: library@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>University Research Center</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: urc@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Center for University Extension Service</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: cues@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>University Health Service</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: uhs@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>National Service Training Program</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: nstp@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Human Resource Development Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: hrdo@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Procurement Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: procurement@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Property and Supplies Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: pso@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Physical Facilities Management Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: pfmo@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>University Security Office</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: uso@plm.edu.ph</Typography>
      </Box>
      <br/>
      <br/>
      <br/>
      </Box>
      
      </Box>
      
      <Footer/>
    </div>
  );
}

export default ContactOffice;
