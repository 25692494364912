import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeCE = () => {

  const ce = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem
                  onClick={() =>
                    ce.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Engineering
                </MenuItem>
                <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
                <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
                <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={ce} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Engineering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Formerly the College of Engineering and Technology, the College of Engineering is one of the Colleges of the University effective 25 January 2024 after the Management Reorganization of the Pamantasan ng Lungsod ng Maynila.  At present the College of Engineering offers the following undergraduate and graduate academic programs.
Undergraduate programs:
<br/>•        BS in Chemical Engineering (BSChE)
<br/>•        BS in Civil Engineering with Specialization in Construction Management (BSCE-CM)
<br/>•        BS in Civil Engineering with Specialization in Structural Engineering (BSCE-SE)
<br/>•        BS in Computer Engineering (BSCpE)
<br/>•        BS in Electrical Engineering (BSEE)
<br/>•        BS in Electronics Engineering (BSECE)
<br/>•        BS in Manufacturing Engineering (BSMFGE)
<br/>•        BS in Mechanical Engineering (BSME)

<br/><br/>Graduate programs:
<br/>•        Master of Engineering with Specialization in Computer Engineering (MEng-CoE)
<br/>•        Master of Engineering with Specialization in Structural Engineering (MEng-SE)
<br/>•        Master of Engineering Management with Specialization in Construction Management (MEM-CM)
<br/>•        Master of Science in Management Engineering (MSManEng)

        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        With the conviction of providing quality education and offering technical manual skills in the field of technology, the College of Engineering was established on July 1, 1969 - six years after the late Mayor Antonio F. Villegas founded the university.
        <br/><br/>Originally under the College of Arts and Letters, the main trust of the college was to provide technical, industrial, vocational education to PLM students alongside the humanistic courses to prepare them for promoting out technology under two divisions, namely - the Division of Engineering and Technology which covered the Department of Civil, Mechanical, Electrical, Sanitary, Chemical, Naval and Industrial Engineering and the Division of Technical and Vocational Education which covered the Department of Electronics, Wood Working, Metal Works, Automotive Works, Ceramics, Graphics Arts and Teacher Education in Arts and Trades. Obtaining a degree in this college then, required the student to finish a six-year ladderized program which was later reduced to a five-year scheme during the term of former PLM President Consuelo Blanco who felt the imperative need of the engineering graduates to constitute the country's labor pool.

        <br/><br/>Today, the College of Engineering stands committed to upholding the legacy conceived by Mayor Villegas and the late Mayor Arsenio H. Lacson by providing its present batch of Engineering students with quality education which is responsive to the needs of the time.

        </Typography>
      </Box>      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Engineering will be the premier college in technological education, research and extension services.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Guided by this vision, we commit ourselves to:
        <br/>1.        Uphold excellence through curriculum development and teaching, significant advances in knowledge, and services to the community of which we are a part.
        <br/>2.        Nurture students with a technological education of the highest quality that will enable them to be professionally competent, community directed, and God centered individuals; and
        <br/>3.        Develop faculty members and staff to be excellent examples in leadership and management.

        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Believing in our mission, we earnestly seek to:
        <br/>1.        Facilitate the achievement of academic goals by regularly reviewing curricular programs, ensuring that they surpass the standards set by governing bodies.
        <br/>2.        Provide a productive environment to facilitate quality research and socially responsive extension service.
        <br/>3.        Develop dynamism among administrators, faculty, student and services personnel, embracing diversities that contribute to the growth of the college.
        <br/>4.        Strengthen our ties with our alumni and industry partners, helping us establish a distinct place in the industry.

        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Bachelor of Science in Chemical Engineering (BSChE)
        <br/>Program Description:
        <br/>The Bachelor of Science in Chemical Engineering (BSCHE) program at the Pamantasan ng Lungsod ng Maynila (PLM) is a four-year degree program that envisions itself as a center of excellence, a leading institution for research, and with highly qualified faculty members duly recognized for producing technically competent, socially involved, and globally responsive professionals. The program is committed to providing students with a strong theoretical foundation and practical skills essential for success in the field. It also emphasizes the importance of ethical and social responsibility in engineering practice.

        <br/><br/>Key areas of study include:
        <br/>·        Chemical Engineering Fundamentals: Thermodynamics, fluid mechanics, heat transfer, mass transfer, reaction kinetics, and process control.
        <br/>·        Chemical Process Design: Development of flowsheets, equipment selection, process optimization, and economic evaluation.
        <br/>·        Laboratory and Pilot Plant Operations: Hands-on experience in conducting experiments, analyzing data, and scaling up processes.
        <br/>·        Industrial Safety and Environmental Protection: Emphasis on safe practices, hazard identification, risk assessment, and pollution prevention.
        <br/><br/>Graduates of the program are well-prepared for careers in various industries, such as:
        <br/>·        Chemical Manufacturing: Petrochemicals, pharmaceuticals, food processing, and consumer goods.
        <br/>·        Energy Production: Oil and gas, renewable energy, and nuclear power.
        <br/>·        Environmental Engineering: Pollution control, waste treatment, and resource recovery.
        <br/>·        Research and Development: Development of new products and processes.

        <br/><br/>Bachelor of Science in Civil Engineering (BSCE)
        <br/>Program Description:
        <br/>The Bachelor of Science in Civil Engineering (BSCE) program is designed to cultivate future engineers with a strong foundation in analytical thinking, teamwork, and technical expertise. This program focuses on the comprehensive understanding required for the design, construction, and maintenance of key infrastructure such as roads, bridges, buildings, water supply systems, irrigation, flood control systems, and ports. Students are trained to integrate new knowledge continuously, ensuring they stay at the forefront of technological and methodological advancements in civil engineering.

        <br/>The BSCE program offers a broad-based education that prepares graduates to excel in various fields within civil engineering. Specialized tracks include Construction Management and Structural Engineering. Graduates are not only proficient in technical skills but also embody the core values of “Karunungan, Kaunlaran, and Kadakilaan”. These values inspire them to contribute productively and ethically to society, engaging in nation-building through careers in academe, research, and industry.
 
        <br/><br/>Construction Management Track:
        <br/>The BS in Civil Engineering program with specialization in Construction Management (BSCE-CM) provides students with a comprehensive foundation in general civil engineering principles while also imparting specialized knowledge in construction management. This track ensures that students gain expertise in key areas such as construction cost engineering, advanced construction methods and equipment, project construction and management, total quality management and construction occupational safety and health.
        <br/>Graduates from this program are well-prepared to engage in various civil engineering disciplines, underpinned by a distinctive proficiency in Construction Management. This specialized training enables them to excel in managing construction projects efficiently and effectively, ensuring adherence to safety standards and cost management practices, and utilizing advanced construction techniques and quality management systems.
 
        <br/>Structural Engineering Track:
        <br/>The BS in Civil Engineering Program with specialization in Structural Engineering provides students with a comprehensive foundation in general civil engineering principles while also imparting specialized knowledge in structural engineering. This track ensures that students gain expertise in key areas such as matrix analysis of structures, earthquake engineering, structural design of towers and other vertical structures, bridge engineering and foundation and retaining wall design.
        <br/>Graduates from this program are well-prepared to engage in various civil engineering disciplines, underpinned by a distinctive proficiency in Structural Engineering. This specialized training enables them to excel in designing and analyzing complex structures, ensuring they meet safety, durability, and performance standards.
 
        <br/>Career and Profession:
        <br/>Civil Engineer, Construction Engineer, Structural Engineer, Geotechnical Engineer, Transportation Engineer, Water Resources Engineer, Construction Manager, Quantity Surveyor, Project Manager, Safety Engineer, Environmental Engineer, Academician/Professor, Research and Development Engineer, Urban Planner, and Consulting Engineer.

        <br/><br/>Bachelor of Science in Computer Engineering (BSCpE)
        <br/>Program Description: 
        <br/>The Bachelor of Science in Computer Engineering (BSCpE) is a program that embodies the science and technology of design, development, implementation, maintenance and integration of software and hardware components in modern computing systems and computer-controlled equipment. This includes knowledge in mathematics and engineering sciences, associated with the broader scope of engineering and beyond that narrowly required for the field. It is a preparation for professional practice in engineering.

        <br/>Graduates of BS in Computer Engineering should possess the ability to design computers, computer-based systems and networks that include both hardware and software and their integration to solve novel engineering problems, subject to trade-offs involving a set of competing goals and constraints. In this context, “design” refers to a level of ability beyond “assembling” or “configuring” systems.

        <br/>Career and Profession:
        <br/>Graduates of the BSCpE program can pursue a variety of career paths, including but not limited to the following:
        <br/>Computer Hardware Engineer, Systems Engineer, Software Engineer, Robotics Engineer, Back-end Developer, Full Stack Developer, Network Administrator/Engineer, Computer Programmer, App Developer, IT Security Consultant, Software Quality Assurance Engineer, Front End Software Engineer, Data Engineer/Analyst, Technical Support Specialist, Multimedia Programmer, Web Developer, Forensic Computer Analyst, Game Developer and UX/UI Design Engineer.

        <br/><br/>Bachelor of Science in Electrical Engineering (BSEE)

        <br/>Program Description:
        <br/>The Bachelor of Science in Electrical Engineering (BSEE) is a program that involves the conceptualization, development, design and application of safe, healthy, ethical, economical and sustainable generation, transmission, distribution and utilization of electrical energy for the benefit of society and the environment through the knowledge of mathematics, physical sciences, information technology and other allied sciences, gained by study, research and practice.  Electrical Engineering is one of the broader fields of the engineering disciplines both in terms of the range of problems that fall within its purview and in the range of knowledge required to solve these problems.
        <br/>Career and Profession:
        <br/>The scope of practice of Electrical Engineering is defined in Section 2a of the prevailing Electrical Engineering Law or RA 7920 and pertains to professional services and expertise including but not limited to:
        <br/>•        Consultation, investigation, valuation and management of services requiring electrical engineering knowledge 
        <br/>•        Design and preparation of plans, specifications and estimates for electric power systems, power plants, power distribution systems including power transformers, transmission lines and network protection, switchgear, building wiring, electrical machines equipment and others; 
        <br/>•        Supervision of erection, installation, testing and commissioning of power plants, substations, transmission lines, industrial plants and others;
        <br/>•        Supervision of operation and maintenance of electrical equipment in power plants, industrial plants, watercrafts, electric locomotives and others;
        <br/>•        Supervision in the manufacture and repair of electrical equipment including switchboards, transformers, generators, motors, apparatus and others;
        <br/>•        Teaching of electrical engineering professional courses; and 
        <br/>•        Taking charge of the sale and distribution of electrical equipment and systems requiring engineering calculations or applications of engineering data.
        <br/>The fields of specialization may include, but not limited to, the following: power system operation and protection, power plant operation and maintenance, advanced electrical systems design and Inspection, sales and entrepreneurship, engineering education and research, instrumentation and control systems, construction and project management: software development, electricity market, and safety engineering.

        <br/><br/>Bachelor of Science in Electronics Engineering (BSECE)

        <br/>Program Description:
        <br/>The Bachelor of Science in Electronics Engineering (BSECE) program of the Pamantasan ng Lungsod ng Maynila is a comprehensive undergraduate degree that equips students with the knowledge and skills necessary to design, develop, and maintain electronic systems and devices. The program aligns with the country's goals to produce globally competitive engineers who can contribute to technological advancements both locally and internationally.
        <br/>The BSECE program is tailored to meet the specific needs of the Philippine electronics industry, which is a key sector in the country’s economy. Graduates are prepared to work in various local industries, including telecommunications, semiconductor manufacturing, consumer electronics, and information technology. The program also emphasizes the development of solutions to local challenges, such as improving communication infrastructure in remote areas and advancing local manufacturing capabilities.
        <br/>The curriculum is designed to meet international standards, ensuring that graduates are competitive in the global job market. The program adheres to guidelines set by the Commission on Higher Education (CHED). The emphasis on modern and emerging technologies, such as IoT (Internet of Things), AI (Artificial Intelligence), and renewable energy systems, prepares graduates for cutting-edge roles in the global electronics industry. Strong English language instruction and multicultural education also prepare students for international collaboration and employment.
        <br/>An Electronics Engineer is a professional who conceptualizes, develops, designs, improves and applies safe, healthy, ethical and economic ways in the field of electronics for the benefit of society and environment through the knowledge of basic sciences and mathematics, physical sciences, basic engineering sciences, information technology, electronics engineering and other natural, applied and social sciences, gained by study, research and practice.
  
        <br/>Career and Profession:
        <br/>Graduates of the BSECE program can pursue a variety of career paths, including but not limited to:
        <br/>·        Electronics Design Engineer
        <br/>·        Semiconductor Engineer
        <br/>·        Telecommunications Engineer
        <br/>·        Network Engineer
        <br/>·        Broadcast Engineer
        <br/>·        Acoustic Engineer
        <br/>·        Control Systems Engineer
        <br/>·        Instrumentation Engineer
        <br/>·        Power Electronics Engineer
        <br/>·        Biomedical Engineer
        <br/>·        Test Engineer
        <br/>·        Data Engineer
        <br/>·        Software Engineer
        <br/>·        Robotics Engineer
        <br/>·        Artificial Intelligence Engineer
        <br/>·        IoT Engineer
        <br/>·        Embedded Systems Engineer
        <br/>·        Technical Sales Engineer
        <br/>·        Research and Development Engineer
        <br/>·        Academic and Research Positions

        <br/><br/>Bachelor of Science in Manufacturing Engineering (BSMfgE)
        <br/>Program Description: 
        <br/>The Bachelor of Science in Manufacturing Engineering (BSMfgE) is a program that concerns itself with the understanding and application of engineering procedures in manufacturing processes and production methods.  It requires the ability to plan the practices of manufacturing; research; develop tools, processes, machines and equipment; and to integrate the facilities and systems for producing quality product with the optimum expenditure of capital.  
        <br/>Career and Profession:
        <br/>The scope of the practice of Manufacturing Engineering includes but is not limited to the following professional services in terms of consultation requiring manufacturing engineering knowledge, skills, and proficiency; design of equipment and processes in a manufacturing industry; operation and maintenance of a manufacturing plant; quality assurance; research and development; and teaching in the academe.  

        <br/><br/>Bachelor of Science in Mechanical Engineering (BSME)
        <br/>Program Description:
        <br/>The Bachelor of Science in Mechanical Engineering concerns itself with mechanical design, energy conversion, fuel and combustion technologies, heat transfer, materials, noise control and acoustics, manufacturing processes, rail transportation, automatic control, product safety and reliability, solar energy, and technological impacts to society.

        <br/>Career and Profession:
        <br/>The scope of the practice of Mechanical Engineering pertains to professional services to industrial plants in terms of: consultation requiring mechanical engineering knowledge, skill and proficiency; investigation; estimation and or valuation; planning, preparation of feasibility studies; designing; preparation of specifications; supervision of installation; operation including quality management; and research, among others.

        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
BSCHE:
<br/>The program has received various recognitions in recent years as shown in the Chemical Engineering Licensure Examinations:
<br/>•        Top 2 Performing School - May 2023 (90.48% vs. 58.93% National Passing Rate)
<br/>•        Top 5 Performing School - October 2023 (83.33% vs 70.23% National Passing Rate)
<br/>•        Top 2 Performing School - May 2024 (89.19% vs 68.97% National Passing Rate)

<br/>Moreover, the program has garnered individual awards for students achieving the Top 10 places in the recent Chemical Engineers Licensure Examinations:
<br/>•        Engr. Reindell N. Mallari                                -         Top 6 (May 2023)
<br/>•        Engr. Christian Jaimie S. Rejano                 -         Top 7 (May 2024)

<br/><br/>BSCE:
<br/>The program has received various recognitions in recent years as shown in the Civil Engineering Licensure Examinations:
<br/>•        Top 2 Performing School for the April 2023 Civil Engineering Licensure Examination (CELE) with 50 or more examinees and with at least 80% Passing Percentage by PRC.
<br/>•        Engr. Dale Iverson N. Lacastre, 7th Placer, November 2021 PRC CELE
<br/>•        Engr. Jolison Kenneth G. Arita, 7th Placer, April 2023 PRC CELE

<br/><br/>BSCpE:
<br/>Joniel Bolocon
<br/>3rd Year CPE
<br/>Student Representative to Solana APAC Summit 
<br/>Kuala Lumpur, Malaysia
<br/>June 20-22, 2024

<br/><br/>Georgeson Yssrael Guray
<br/>4th Year CPE
<br/>Student Representative to Road to Breakpoint 2024
<br/>Empowering Student Builders
<br/>Singapore
<br/>September 21-22, 2024

<br/><br/>BSEE:
<br/>•        PLM overall rating is 92.31% vs 63.33% of the national rating in the April 2024 Electrical Engineering Licensure Examination (EELE)
<br/>•        Engr. Jamael M. Codaye placed 9th in the Top 10 of successful examinees in the April 2024 Electrical Engineering Licensure Examination (EELE)

<br/>BSMfgE:
<br/>Member, Society of Manufacturing Engineers Student Chapter, Michigan, U.S.A.

<br/>BSME:
<br/>•        Engr. Allen Paul L. Jasareno, 10th Place in the February 2024 Mechanical Engineering Licensure Examination (MELE)
<br/>•        Member, American Society of Heating, Refrigeration, Airconditioning, and Ventilation Engineers Student Chapter, Atlanta, Georgia, U.S.A.


      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeCE;
