// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChatBot Tooltip */

.rcb-chat-tooltip {
	position: fixed;
	padding: 16px;
	border-radius: 20px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
	cursor: pointer;
	font-size: 20px;
	transition: transform 0.3s ease;
}

.rcb-chat-tooltip-tail {
	content: "";
	position: absolute;
	top: 50%;
	right: -10px;
	margin-top: -10px;
	border-width: 10px 0 10px 10px;
	border-style: solid;
}

.rcb-chat-tooltip.rcb-tooltip-hide {
	opacity: 0;
	visibility: hidden;
	animation: tooltip-out 0.5s ease-in-out;
}

.rcb-chat-tooltip.rcb-tooltip-show {
	opacity: 1;
	visibility: visible;
	animation: tooltip-in 0.5s ease-in-out;
}

@keyframes tooltip-in {
	0% {
	  opacity: 0;
	  transform: translateY(-5px);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  
@keyframes tooltip-out {
	0% {
	  opacity: 1;
	  transform: translateY(0);
	}
	100% {
	  opacity: 0;
	  transform: translateY(-5px);
	}
}`, "",{"version":3,"sources":["webpack://./src/components/ChatBotTooltip/ChatBotTooltip.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;CACC,eAAe;CACf,aAAa;CACb,mBAAmB;CACnB,wCAAwC;CACxC,mBAAmB;CACnB,eAAe;CACf,eAAe;CACf,+BAA+B;AAChC;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,QAAQ;CACR,YAAY;CACZ,iBAAiB;CACjB,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC,UAAU;CACV,kBAAkB;CAClB,uCAAuC;AACxC;;AAEA;CACC,UAAU;CACV,mBAAmB;CACnB,sCAAsC;AACvC;;AAEA;CACC;GACE,UAAU;GACV,2BAA2B;CAC7B;CACA;GACE,UAAU;GACV,wBAAwB;CAC1B;EACC;;AAEF;CACC;GACE,UAAU;GACV,wBAAwB;CAC1B;CACA;GACE,UAAU;GACV,2BAA2B;CAC7B;AACD","sourcesContent":["/* ChatBot Tooltip */\r\n\r\n.rcb-chat-tooltip {\r\n\tposition: fixed;\r\n\tpadding: 16px;\r\n\tborder-radius: 20px;\r\n\tbox-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);\r\n\twhite-space: nowrap;\r\n\tcursor: pointer;\r\n\tfont-size: 20px;\r\n\ttransition: transform 0.3s ease;\r\n}\r\n\r\n.rcb-chat-tooltip-tail {\r\n\tcontent: \"\";\r\n\tposition: absolute;\r\n\ttop: 50%;\r\n\tright: -10px;\r\n\tmargin-top: -10px;\r\n\tborder-width: 10px 0 10px 10px;\r\n\tborder-style: solid;\r\n}\r\n\r\n.rcb-chat-tooltip.rcb-tooltip-hide {\r\n\topacity: 0;\r\n\tvisibility: hidden;\r\n\tanimation: tooltip-out 0.5s ease-in-out;\r\n}\r\n\r\n.rcb-chat-tooltip.rcb-tooltip-show {\r\n\topacity: 1;\r\n\tvisibility: visible;\r\n\tanimation: tooltip-in 0.5s ease-in-out;\r\n}\r\n\r\n@keyframes tooltip-in {\r\n\t0% {\r\n\t  opacity: 0;\r\n\t  transform: translateY(-5px);\r\n\t}\r\n\t100% {\r\n\t  opacity: 1;\r\n\t  transform: translateY(0);\r\n\t}\r\n  }\r\n  \r\n@keyframes tooltip-out {\r\n\t0% {\r\n\t  opacity: 1;\r\n\t  transform: translateY(0);\r\n\t}\r\n\t100% {\r\n\t  opacity: 0;\r\n\t  transform: translateY(-5px);\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
