import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.css";
import { Key, useEffect, useState } from "react";
import axios from "axios";
import { Book } from "./PLMCarousel";
// import sprite from "./sprite.svg";
import myfile from './carousel.json';


function Carousel1() {
    // const API_BASE_URL = "https://19.65.23.253";
    // const API_BASE_URL = "localhost";

    const [images, setCards] = useState<Book[]>([]);
    
    const fetchImages = async () => {
        try {
          // const response = await axios.get(`${API_BASE_URL}/plmapi/carouselimage.php`);
          setCards(myfile);
        console.log(myfile);
        } catch (err: any) {
          console.error(err.message);
        }
      };
    
useEffect(() => {
    fetchImages();
    // eslint-disable-next-line
  }, []);

    const rotateAnimationHandler = (props: { transitionTime: string; }, state: { previousItem: number; selectedItem: number; }) => {
    const transitionTime = props.transitionTime + "ms";
    const transitionTimingFunction = "ease-in-out";
    let slideStyle = {
      display: "block",
      minHeight: "50%",
      transitionTimingFunction: transitionTimingFunction,
      msTransitionTimingFunction: transitionTimingFunction,
      MozTransitionTimingFunction: transitionTimingFunction,
      WebkitTransitionTimingFunction: transitionTimingFunction,
      OTransitionTimingFunction: transitionTimingFunction,
      transform: `rotate(0)`,
      position:
        state.previousItem === state.selectedItem ? "relative" : "absolute",
      inset: "0 0 0 0",
      zIndex: state.previousItem === state.selectedItem ? "1" : "-2",
      opacity: state.previousItem === state.selectedItem ? "1" : "0",
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
    return {
      slideStyle,
      selectedStyle: {
        ...slideStyle,
        opacity: 1,
        position: "relative",
        zIndex: 2,
        filter: `blur(0)`,
      },
      prevStyle: {
        ...slideStyle,
        transformOrigin: " 0 100%",
        transform: `rotate(${
          state.previousItem > state.selectedItem ? "-45deg" : "45deg"
        })`,
        opacity: "0",
        filter: `blur( ${
          state.previousItem === state.selectedItem ? "0px" : "5px"
        })`,
      },
    };
  };
  return (
    <div className="box">
      <Carousel
        showIndicators={false}
        statusFormatter={undefined}
        transitionTime={310}
        swipeable={false}
        infiniteLoop
        autoPlay
        showStatus={false}
        stopOnHover
        width="100%"
      >
        {images.map((URL, index) => (
          <div className="slide">
            <img alt="plm updates" src={URL.imagelink} key={index} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
export default Carousel1;



// <Carousel
// showIndicators
// renderArrowNext={(clickHandler, hasNext) => {
//   return (
//     hasNext && (
//       <button className="nav_btn nav_btn_right" onClick={clickHandler}>
//         <svg>
//           {/* <use xlinkHref={sprite + "#right"}></use> */}
//         </svg>
//       </button>
//     )
//   );
// }}
// renderArrowPrev={(clickHandler, hasNext) => {
//   return (
//     hasNext && (
//       <button onClick={clickHandler} className="nav_btn nav_btn_left">
//         <svg>
//           {/* <use xlinkHref={sprite + "#left"}></use> */}
//         </svg>
//       </button>
//     )
//   );
// }}
// renderIndicator={(clickHandler, isSelected, index) => {
//   return (
//     <li
//       onClick={clickHandler}
//       className={`ind ${isSelected ? "active" : ""}`}
//       key={index}
//       role="button"
//     />
//   );
// }}
// statusFormatter={undefined}
// transitionTime={310}
// swipeable={false}        
// >
// {images.map((URL, index) => (
//   <div className="slide">
//     <img alt="plm updates" src={URL.imagelink} key={index} />
//   </div>
// ))}
// </Carousel>