import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const PLMAT = () => {
  const plmat = useRef(document.createElement("p"));

  const handleCMAT = () => {
    window.location.href="./CMAT";
  };

  const handleCLAT = () => {
    window.location.href="./CLAT";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">PLM Admission Test (PLMAT)</Typography>
      </Box>      
      <br/>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "10%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem
          onClick={() =>
            plmat.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          PLM Admission Test (PLMAT)
        </MenuItem>
        <MenuItem onClick={handleCMAT}>College of Medicine Admission Test (CMAT)</MenuItem>
        <MenuItem onClick={handleCLAT}>College of Law Admission Test (CLAT)</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "90%"}}>
      <Box ref={plmat} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">The Pamantasan ng Lungsod ng Maynila (PLM) will start accepting applications (on-line) for freshmen students (undergraduate programs) for Academic Year (AY_ 2024-2025 starting December 01, 2023.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">For AY 2024-2025, there will be an on-site PLM Admission Test (PLMAT). Minimum health protocols will be observed to ensure the safety of student-applicants. PLMAT is a 4-hour examination consisting of sub-tests in English, Science, Mathematics, Filipino and Abstract Reasoning. Passing the PLMAT is one of the requirements for admission to PLM.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Applicants must be prepare the scanned copy (PDF) of the following:</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. PSA Birth Certificate</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. For Senior High School (SHS): Grade 11 Certificate of General Weighted Average (GWA).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. For Alternative Learning System (ALS) completers and Accreditation & Equivalency (A&E) Passers: Certificate of Completion</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Who may apply?</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. Senior High School (SHS) student who is currently enrolled in Grade 12 Department of Education (DepEd) accredited senior high school with a grade 11 general weighted average (GWA) of 80 or above.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. Senior High School graduate from DepEd accredited senior high school with a grade 11 general weighted average (GWA) of 80 or above and has not taken any college or university units or programs during the application period.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. The applicant completed Alternative Learning System (ALS) and passed the Accreditation & Equivalency (A&E) as recommended for tertiary education and has not taken any college or university units/programs during the application period.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">How to apply?</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. Apply on-line through the PLM Admission Portal.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. Fill out the Application Form (online).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. Upload required application requirements (online).<br/>a. PSA Birth Certificate<br/>b. Certificate of Grade 11 GWA (SHS) /Certificate of Completion (ALS).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">4. Check email for the account credentials after submitting the application successfully. Log in to PLM admission account on a regular basis to check the status of the application.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">5. Upon evaluation of the information and requirements, print the Application Form.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">6. Upload scanned-signed Application Form with ID picture (2 x 2 colored with white background).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">7. Monitor the status of the Application Form at the PLM Admission Account.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">8. Check email for the PLMAT examination schedule after the Application Form has been successfully evaluated.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">9. Print two (2) copies of the exam permit.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">10. On the specified examination date, bring two (2) signed exam permits with an ID picture (2x2 colored with white background) and the signed Application Form with ID picture (2x2 colored with white background).</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <a href='./College'> <Typography variant="h6" alignContent="left">Programs in PLM</Typography></a>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">For more information, you may contact the Admissions Office:</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Email: <a href="mailto:admission_office@plm.edu.ph">admission_office@plm.edu.ph</a></Typography>
      </Box>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default PLMAT;
