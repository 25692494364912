// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChatBot Toggle Button */

.rcb-toggle-button {
	display: flex;
	flex-direction: row;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
	width: 75px;
	height: 75px;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	background-size: cover;
}

.rcb-toggle-button.rcb-button-hide {
	opacity: 0;
	visibility: hidden;
	animation: collapse 0.3s ease-in-out forwards;
}

.rcb-toggle-button.rcb-button-show {
	opacity: 1;
	visibility: visible;
	animation: expand 0.3s ease-in-out forwards;
}

@keyframes expand {
	0% {
		transform: translate(100%, 100%) scale(0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}

@keyframes collapse {
	0% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
	100% {
		transform: translate(100%, 100%) scale(0);
		opacity: 0;
	}
}

/* ChatBot Notification Badge*/

.rcb-badge {
	position: absolute;
	top: -6px;
	right: -6px;
	padding: 5px 10px;
	border-radius: 50%;
	background-color: #ff0000;
	color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatBotButton/ChatBotButton.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;CACC,aAAa;CACb,mBAAmB;CACnB,eAAe;CACf,YAAY;CACZ,WAAW;CACX,aAAa;CACb,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,YAAY;CACZ,eAAe;CACf,wCAAwC;CACxC,sBAAsB;AACvB;;AAEA;CACC,UAAU;CACV,kBAAkB;CAClB,6CAA6C;AAC9C;;AAEA;CACC,UAAU;CACV,mBAAmB;CACnB,2CAA2C;AAC5C;;AAEA;CACC;EACC,yCAAyC;EACzC,UAAU;CACX;CACA;EACC,mCAAmC;EACnC,UAAU;CACX;AACD;;AAEA;CACC;EACC,mCAAmC;EACnC,UAAU;CACX;CACA;EACC,yCAAyC;EACzC,UAAU;CACX;AACD;;AAEA,8BAA8B;;AAE9B;CACC,kBAAkB;CAClB,SAAS;CACT,WAAW;CACX,iBAAiB;CACjB,kBAAkB;CAClB,yBAAyB;CACzB,WAAW;AACZ","sourcesContent":["/* ChatBot Toggle Button */\r\n\r\n.rcb-toggle-button {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tposition: fixed;\r\n\tbottom: 20px;\r\n\tright: 20px;\r\n\tz-index: 9999;\r\n\twidth: 75px;\r\n\theight: 75px;\r\n\tborder-radius: 50%;\r\n\tborder: none;\r\n\tcursor: pointer;\r\n\tbox-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\r\n\tbackground-size: cover;\r\n}\r\n\r\n.rcb-toggle-button.rcb-button-hide {\r\n\topacity: 0;\r\n\tvisibility: hidden;\r\n\tanimation: collapse 0.3s ease-in-out forwards;\r\n}\r\n\r\n.rcb-toggle-button.rcb-button-show {\r\n\topacity: 1;\r\n\tvisibility: visible;\r\n\tanimation: expand 0.3s ease-in-out forwards;\r\n}\r\n\r\n@keyframes expand {\r\n\t0% {\r\n\t\ttransform: translate(100%, 100%) scale(0);\r\n\t\topacity: 0;\r\n\t}\r\n\t100% {\r\n\t\ttransform: translate(0, 0) scale(1);\r\n\t\topacity: 1;\r\n\t}\r\n}\r\n\r\n@keyframes collapse {\r\n\t0% {\r\n\t\ttransform: translate(0, 0) scale(1);\r\n\t\topacity: 1;\r\n\t}\r\n\t100% {\r\n\t\ttransform: translate(100%, 100%) scale(0);\r\n\t\topacity: 0;\r\n\t}\r\n}\r\n\r\n/* ChatBot Notification Badge*/\r\n\r\n.rcb-badge {\r\n\tposition: absolute;\r\n\ttop: -6px;\r\n\tright: -6px;\r\n\tpadding: 5px 10px;\r\n\tborder-radius: 50%;\r\n\tbackground-color: #ff0000;\r\n\tcolor: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
