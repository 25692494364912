import './App.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import plmarea from './img/plmarea.png';
const Landmark = () => {
  return (
    <>
    <hr />
    <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '24px 48px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
      <Typography color="#A31920" variant="h5">LANDMARK</Typography><br/>
    </Box>

    <Grid container sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
      <Grid item xs={4} alignContent="right" padding="16px 0px 16px 48px">
        <img src={plmarea} width="85%" height="85%" alt="" style={{  border: "solid 3px #A31920", margin: "1px"}} />
      </Grid>
      <Grid item xs={4} alignContent="left" padding="16px 0px 16px 24px">
        <iframe title="Landmark" style={{  width:"85%",  height:"85%", border: "solid 3px #A31920", margin: "1px"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23962.437596302647!2d120.97360814421928!3d14.5936521285599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397ca2336770053%3A0x1b731714778d3506!2sPamantasan%20ng%20Lungsod%20ng%20Maynila!5e0!3m2!1sen!2sph!4v1712038127719!5m2!1sen!2sph" width="100%" height="400" loading="lazy"></iframe>
      </Grid>
      <Grid item xs={4} paddingLeft="48px" paddingTop="36px">
        <Typography alignItems="left" fontSize= "1.25vw">Address: Gen. Luna corner Muralla St., Intramuros Manila, Philippines 1002</Typography>
        <br/>
        <Typography alignItems="left" fontSize= "1.25vw">email: plmwebsite@plm.edu.ph</Typography>
        <br/>
      </Grid>
    </Grid>
    </>
  );
}

export default Landmark;
