import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeCTHM = () => {

  const cthm = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem
                  onClick={() =>
                    cthm.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Tourism & Hotel Management
                </MenuItem>
                <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
                <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
                <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
                <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
                <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={cthm} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Tourism and Hospitality Management</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">

        The College of Tourism and Hospitality Management is a department within a university that focuses on preparing students for careers in the tourism and hospitality industries. These industries are broad and encompass a variety of businesses and services related to travel and leisure.

<br/>Here’s a general overview of what a college of tourism and hospitality management might offer:

<br/>Programs:
<br/>Bachelor of Science in Tourism Management
<br/>Bachelor of Science in Hospitality Management
<br/>These programs typically take four years to complete.
<br/>Curriculum:
<br/>Courses in business fundamentals, marketing, events management, and finance
<br/>Courses specific to hospitality and tourism, such as hotel operations, restaurant management, destination management, and event planning, kitchen essentials, baking, Barista, bar and beverage.
<br/>Courses that develop transferable skills, such as communication, critical thinking, problem-solving, and intercultural competence.
<br/>Internships: Many programs offer internship opportunities that allow students to gain practical experience in the hospitality and tourism industries.
<br/>The goals of a college of tourism and hospitality management program are typically to:

<br/><br/>Provide students with the knowledge and skills necessary to be successful in the tourism and hospitality industries.
<br/>Prepare students for a variety of careers in these industries, such as hotel management, restaurant management, event planning, travel agency management, and destination marketing.
<br/>Instill in students a strong work ethic and a commitment to providing excellent customer service.
<br/>If you are interested in a career in the exciting and ever-growing tourism and hospitality industries, a college of tourism and hospitality management program may be a good option for you.
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        In February 2024, in a Board of Regents meeting, a proposal entitled “Reorganization of the Different Colleges and Academic Units in line with the Restructuring Efforts of the Administration” was presented.  One of the concerns in the said proposal was to separate the Department of Tourism Management and the Hospitality Management from the PLMBS which is now the College of Tourism and Hospitality Management. That has 2 courses namely:

<br/>a) BS Tourism Management
<br/>b) BS Hospitality Management
        </Typography>
      </Box>      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The Pamantasan ng Lungsod ng Maynila shall be the premier people's university pursuing public interest and national development of skilled individuals.        
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          The Pamantasan ng Lungsod ng Maynila shall form critical-minded and service-oriented leaders and innovators in various disciplines through accessible and relevant quality education, transformative institutional research and extension services, and key linkages.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Guided by the college's vision and mission, we commit to:

<br/>-To establish academic achievement, competency, collaboration and ethical values in every student in order for them to be competent individuals. 
<br/>-To improve faculty expertise while also strengthening work ethics and values through continuing faculty development programs that will contribute to the college's efficient and effective education. 
<br/>-To build strong industry partnerships to provide Tourism management and Hospitality Management students with hands-on training and practical knowledge, preparing and equipped them with skills for employment in tourism and hospitality management.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Bachelor of Science in Tourism Management

<br/>The BS Tourism Management is a four-year program that focuses on equipping students with the knowledge and skills that is required in order to manage and promote tourism activities/programs effectively. This program covers various aspects of tourism such as Events Management, Tourism Economics, Travel Services Management and Practical Training. Graduates in BS Tourism Management can pursue a range of careers such as Travel Consultant, Tour Operator and Event Planner.
<br/><br/>Bachelor of Science in Hospitality Management

<br/>The BS Hospitality Management is four-year college course that is designed to prepare the students for the hospitality industry. This program includes courses on Hotel and Restaurant Management, Food and Beverage Service, Event Planning, Guest Relations and Practical Training. Students will learn more about the operational and strategic aspects of the hospitality industry, enabling them to work in hotels, restaurants and even resorts. Graduates can pursue careers such as Hotel Managers, Event Coordinators and Restaurant Managers.

        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        -Dean's Lister 
        </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeCTHM;
