import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import vp1 from './img/RONALD_ROMERO.jpg';
import vp2 from './img/RONALDO_TAN.jpg';
import vp3 from './img/vp_luz.jpg';
import vp4 from './img/DAN_CORTEZ.jpg';
import { useRef } from 'react';

const VPresident = () => {
  const board = useRef(document.createElement("p"));
  const vp = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Vice Presidents</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem
          onClick={() =>
            board.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Board of Regents
        </MenuItem>
        <MenuItem onClick={handleBoard}>The President</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem
          onClick={() =>
            vp.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Vice President
        </MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={vp} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      {/* <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Vice Presidents</Typography>
      </Box>
      <br/> */}
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={vp1} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={vp2} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={vp3} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
        <img src={vp4} width="25%" alt="" style={{  border: "solid 3px red", margin: "1px"}} />
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default VPresident;
