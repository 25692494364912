// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading Spinner Container */

.rcb-spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 5px;
	min-height: 35px;
	max-height: 45px;
}

/* Loading Spinner */

.rcb-spinner {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: 4px solid #f3f3f3;
	animation: rcb-spin 1s linear infinite;
}
  
@keyframes rcb-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/LoadingSpinner.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA,oBAAoB;;AAEpB;CACC,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,yBAAyB;CACzB,sCAAsC;AACvC;;AAEA;CACC;EACC,uBAAuB;CACxB;CACA;EACC,yBAAyB;CAC1B;AACD","sourcesContent":["/* Loading Spinner Container */\r\n\r\n.rcb-spinner-container {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tpadding-top: 10px;\r\n\tpadding-bottom: 5px;\r\n\tmin-height: 35px;\r\n\tmax-height: 45px;\r\n}\r\n\r\n/* Loading Spinner */\r\n\r\n.rcb-spinner {\r\n\twidth: 22px;\r\n\theight: 22px;\r\n\tborder-radius: 50%;\r\n\tborder: 4px solid #f3f3f3;\r\n\tanimation: rcb-spin 1s linear infinite;\r\n}\r\n  \r\n@keyframes rcb-spin {\r\n\t0% {\r\n\t\ttransform: rotate(0deg);\r\n\t}\r\n\t100% {\r\n\t\ttransform: rotate(360deg);\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
