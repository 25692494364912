// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Line Break Container */

.rcb-line-break-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 5px;
	max-height: 45px;
}

/* Line Break Text */

.rcb-line-break-text {
	padding: 6px 12px;
	color: #adadad;
	font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatHistoryLineBreak/ChatHistoryLineBreak.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA,oBAAoB;;AAEpB;CACC,iBAAiB;CACjB,cAAc;CACd,eAAe;AAChB","sourcesContent":["/* Line Break Container */\r\n\r\n.rcb-line-break-container {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tpadding-top: 10px;\r\n\tpadding-bottom: 5px;\r\n\tmax-height: 45px;\r\n}\r\n\r\n/* Line Break Text */\r\n\r\n.rcb-line-break-text {\r\n\tpadding: 6px 12px;\r\n\tcolor: #adadad;\r\n\tfont-size: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
