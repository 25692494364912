import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CRS = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Computerized Registration System (CRS)</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Pamantasan ng Lungsod ng Maynila students may register online and access their student records using the links below: </Typography>
      </Box>
      <br/>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6"><a href="https://web1.plm.edu.ph/crs/">Undergraduate and College of Law students</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6"><a href="https://web3.plm.edu.ph/crscm/">College of Medicine students</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6"><a href="https://web2.plm.edu.ph/gsp/">Graduate students</a></Typography>
      </Box>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default CRS;
