import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
function Career() {
  type resultProps = {
    id: string;
    title: string;
    imagelink: string;
    validuntil: string;
    readmorelink: string;
    description: string;
    description1: string;
    description2: string;
    date: string
  };
  
  const [result, setResult] = useState<resultProps[]>([]);
  
  useEffect(() => {
    const car = async () => {
      const data = await fetch("https://19.65.23.253/plmapi/bids.php", {
        method: "GET"
      });
      const jsonData = await data.json();
      setResult(jsonData);
    };
    car();
  }, []);

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'title',
      headerName: 'Project Title',
      width: 250,
      editable: true,
    },
    {
      field: 'budget',
      headerName: 'ABC (Budget)',
      width: 250,
      editable: true,
    },
    {
      field: 'date',
      headerName: 'Published Date',
      width: 200,
      editable: true,
    },
  ];
  
  const rows = [
    { id: 1, title: 'Invitation to Bid: Purchase of Medical Equipment of the College of Medicine (CM)', budget: 'PhP1,639,264.00', date:'18 July 2024'},
    { id: 2, title: 'SUPPLEMENTAL/BID BULLETIN No. 12-G-2024 July 9, 2024 - SUPPLY, DELIVERY, AND INSTALLATION OF AIRCONDITIONING UNITS', budget: '', date:'09 July 2024'},
    { id: 3, title: 'SUPPLEMENTAL/BID BULLETIN No. 11-G-2024 July 2, 2024 - DIGITIZATION OF RECORDS (PHASE III)', budget: '', date:'02 July 2024'},
    { id: 4, title: 'SUPPLEMENTAL/BID BULLETIN No. 10-G-2024 June 19, 2024 - Information Technology Equipment', budget: '', date:'21 June 2024'},
    { id: 5, title: 'Invitation to Bid: Technical and Scientific Equipment', budget: 'PhP9,827,791.00', date:'20 June 2024'},
    { id: 6, title: 'Invitation to Bid: Supply, Delivery, and Installation of Airconditioning Units', budget: 'PhP14,456,517.00', date:'13 June 2024'},
    { id: 7, title: 'Supplemental/Bid Bulletin No. 09-G-2024 June 6, 2024: RENTAL OF PHOTOCOPYING MACHINE', budget: '', date:'07 June 2024'},
    { id: 8, title: 'Invitation to Bid: Digitization of Records (Phase III)', budget: 'PhP5,000,000.00', date:'31 May 2024'},
    { id: 9, title: 'Invitation to Bid: Information Technology Equipment – Rebidding (Lot No. 1)', budget: 'PhP2,234,302.56', date:'16 May 2024'},
    { id: 10, title: 'Invitation to Bid: Rental of Photocopying Machine', budget: 'PhP2,000,000.00', date:'15 May 2024'},
    { id: 11, title: 'Supplemental/Bid Bulletin No. 07-G-2024 April 5, 2024: Provision for Purchase of Cadavers - Rebidding', budget: '', date:'02 May 2024'},
    { id: 12, title: 'Supplemental/Bid Bulletin No. 08-G-2024 April 24, 2024: PROVISION FOR PURCHASE OF CADAVERS - REBIDDING', budget: '', date:'25 April 2024'},
    { id: 13, title: 'Supplemental/Bid Bulletin No. 06-G-2024 April 8, 2024: Voice Based Communication Solutions - Rebidding', budget: '', date:'08 April 2024'},
    { id: 14, title: 'Invitation to Bid: Provision for Purchase of Cadavers - Rebidding', budget: 'PhP1,056,000.00', date:'04 April 2024'},
    { id: 15, title: 'Supplemental/Bid Bulletin No. 05-G-2024 March 22, 2024: PLM Digitalization through Integrated Systems', budget: '', date:'26 March 2024'},
    { id: 16, title: 'Invitation to Bid: Voice Based Communication Solutions - Rebidding', budget: '', date:'15 March 2024'},
    { id: 17, title: 'Supplemental/Bid Bulletin No. 04-G-2024 March 7, 2024: Purchase of Medical Equipment', budget: '', date:'08 March 2024'},
    { id: 18, title: 'Invitation to Bid: PLM Digitalization through Integrated Systems (Multi-Year Contract)', budget: 'PhP60,000,000.00', date:'07 March 2024'},
    { id: 19, title: 'Invitation to Bid: Replacement of Main Feederline of Pride Hall Building', budget: 'PhP1,800,000.00', date:'22 February 2024'},
    { id: 20, title: 'Invitation to Bid: Purchase of Medical Equipment', budget: 'PhP1,522,334.00', date:'21 February 2024'},
  ];
  
 return (
  <>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Bids and Awards</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">Annual Procurement Plan</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        The Annual Procurement Plan (APP) reflects the university's procurement activities for a specific calendar year.
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        The plan, along with other pertinent procurement-related documents, are publicly available on PLM's website for transparency and accountability, and as prescribed by Republic Act No. 9184 or the Government Procurement Reform Act.
        </Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Approved_Annual_Procurement_Plan_for_CY_2024.pdf">Annual Procurement Plan for Calendar Year 2024</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/PLM-Procurement_Monitoring_Report_as_of_October_31_2023.pdf">Procurement Monitoring Report (PMR) as of October 31, 2023</a>
        </Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">Consolidated Updates of 2023 APP (July-December 2023)</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_of_2023_APP_July-Dec_2023_rotated_1.pdf">Consolidated Updates of 2023 APP (July-December 2023)</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_of_2023_APP_July-Dec_2023_rotated_2.pdf">Consolidated Updates of 2023 Supplemental APP (July-December 2023)</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_of_2023_APP_July-Dec_2023_rotated_3.pdf">Procurement Monitoring Report as of December 2023</a>
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <a href="./img/Consolidated_Updates_and_Supplemental_APP_as_of_October_31_2023.pdf">Consolidated and Supplemental 2023 Annual Procurement</a>
        </Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">Competitive Bidding</Typography>
      </Box>

      <Box sx={{width: "70%", padding: "0px 72px"}}>
        <Box sx={{ height: 'auto', width: '70%'}}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            hideFooter
          />
        </Box>
    </Box>

      
      
      {/* {result && result.map((value) => {
         return (
           <Grid container key={value.id} justifyContent= "center">
             <Grid item xs={12} padding="16px 0px 16px 96px" alignContent="center">
               <Typography variant="h5" alignContent="center">{value.title}</Typography>
               <Typography alignItems="left">{value.description}</Typography>
               <Typography alignItems="left">{value.description1}</Typography>
               <Typography alignItems="left">{value.description2}</Typography>
             </Grid>
           </Grid>);
       })}      */}
      <br/>
      <br/>
      <br/>
      <Footer/>
       </>
 );
}

export default Career;