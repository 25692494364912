import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const Download = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Downloads</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Academic Reports</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">1. <a href="#">Academic Report 2023</a></Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Documents and forms for PLM students, faculty, and administrative workers may be downloaded here.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">I. Manuals and resource materials</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">1. <a href="https://www.plm.edu.ph/Students'%20Faculty%20Evaluation%20System%20manual">Students' Faculty Evaluation System manual</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">2. <a href="https://archive.plm.edu.ph/downloads/OGTS/PLM_Suicide_Protocol.pdf" target="_blank">PLM Suicide Prevention and Postvention Protocol: A Guide for the School Community</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">3. <a href="https://archive.plm.edu.ph/downloads/University%20Health%20Services%20Orientation%20for%20Scholars.pdf">University Health Services Orientation for Scholars</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">4. <a href="https://archive.plm.edu.ph/downloads/BROCHURE_PROCUREMENT_TRAINING.pdf">Professionalization Program for Public Procurement Practitioners brochure</a></Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">II. Forms for students</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">1. Scholarship forms:</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">a. <a href="https://archive.plm.edu.ph/downloads/OVPPA/Scholarships/Scholarship_Application_Form_2019.pdf">Scholarship application form (revised 2019)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">b. <a href="https://archive.plm.edu.ph/downloads/OVPPA/Scholarships/Scholarship_Application_Form_2019.pdf">DOH Medical Scholarship form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">c. DOH Scholar Commitment form (<a href="https://www.plm.edu.ph/images/downloads/Apply/DOH_Scholars_Commitment_Form.pdf">PDF</a> and <a href="https://drive.google.com/file/d/1dl7GEToM0PFlOBtMSUn_kwpp9R8ufaVN/view">editable version</a>)</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">d. <a href="https://www.plm.edu.ph/images/downloads/our/PLM-Data-Privacy-Consent-form-web-v2.jpg">Data Privacy consent form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">2. <a href="https://archive.plm.edu.ph/downloads/OUR/OUR%20Request%20Form.pdf">Request Form for School Credentials</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">3. <a href="https://archive.plm.edu.ph/downloads/OUR/OUR%20Checklist%20of%20Requirements.pdf">Checklist of Requirements for Request for School Credentials</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">4. <a href="https://www.plm.edu.ph/images/downloads/Apply/Philhealth_PMRF_012020.pdf">Philhealth members registration form (PMRF, January 2020 version)</a></Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">III. Forms for faculty and administrative workers</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">1. <a href="https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EQeN33LfxflLg2cP6az7I6ABIUYQbO9wM3wPQyVb0Hzi9w">Guidelines for applicants and new hires</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">2. Personal Data Sheet Forms (2017)</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">a. <a href="https://archive.plm.edu.ph/downloads/HRDO/2017_PDS/CSC_Res_No._1700656_(PDS).png">CSC Reso No. 1700656</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">b. <a href="https://plmedu-my.sharepoint.com/:x:/g/personal/mrcclagman_plm_edu_ph/EcEyC1ylEtlLqQgUZwQdGq4BPk3ybhQA3gK5qIrOfDnQLQ?e=ejCY3M">Personal Data Sheet (CS Form 212)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">c. <a href="https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EVxJNsySb_ZNnGSVtsZ5QN0Bu0N1W6rUtIMZ6UsG7avcyQ?e=2vdcva">Personal Data Sheet (CS Form 212) Attachment - Work Experience Sheet</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">d. <a href="https://archive.plm.edu.ph/downloads/HRDO/2017_PDS/CS_Form_No._212_Revised_Personal_Data_Sheet.xlsx">Personal Data Sheet (CS Form 212) Attachment - Revised Guide to Filling Up the Personal Data Sheet</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '36px 256px 0px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">3. <a href="https://plmedu-my.sharepoint.com/:b:/g/personal/mrcclagman_plm_edu_ph/ES9WHNoazuFPsbA-0wwLnHQB6xL6YZ2Ps-iihbgm-D1ugQ?e=ICiCqe">Philhealth Membership Registration Form (PMRF)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">4. <a href="https://plmedu-my.sharepoint.com/:b:/g/personal/mrcclagman_plm_edu_ph/EQYEQpDCtdRJqY7K7Dn2118BmLMUiR0D0Vyb_goT2twXcA?e=wGBIxU">GSIS Membership Information Sheet (MIF)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">5. <a href="https://plmedu-my.sharepoint.com/:b:/g/personal/mrcclagman_plm_edu_ph/EbJuZBh2c5FDkW0Jgty6TAsB7ky8laxrfTb7vREZCr8hug?e=gPRZ2R">PAGIBIG Member's Data Form (MDF)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">6. <a href="https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EXDHiAQSfdBKp3lnY889nqoBeNYkCzmmKkW9egW1yI_aXw?e=QJ43Us">Statement of Assets, Liabilities, and Net Worth (SALN) form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">7. <a href="https://plmedu-my.sharepoint.com/:x:/g/personal/mrcclagman_plm_edu_ph/ESGN9VbS-r1PlLOdfHVsvxsBgWx3uUwr1M00c2LjUA0ODQ?e=u1qN8Z">Daily Time Record (DTR)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">8. <a href="https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EQXAKm2PyCpMscZJfOJRhSIBoHs6CUfCJCaULxMVBPHRyw?e=DCBY9h">Certification of Assumption to Duty</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">9. <a href="https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EaC-dmSoaP9MtRVJZQ5AbHMB2kjVnqzvFFPD8maT4raPsg?e=H9WwRw">Service Report (for administrative COS workers)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">10. <a href="https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/ETMNW3HGhCNHmeXoJkhTqlgBrWNxcYt4OUIYIT5_7nvA6g?e=E48wKr">Service Report (for faculty)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">11. <a href="https://archive.plm.edu.ph/downloads/HRDO/Staffing_Pattern_Form_NameofOffice.xlsx">Staffing Pattern Form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">12. New SPMS Forms (Nov. 2016)</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">a. <a href="https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/Coaching%20Journal%20Template%20v.2016.docx">Coaching Journal</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">b. <a href="https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/IPCR%20Targets%20v.2016.docx">Individual Performance Contract (IPC) Targets</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">c. <a href="https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/IPCR_Form%20v.2016.docx">Individual Performance Contract Review (IPCR) Form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">d. <a href="https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/OPCR%20v.2016.docx">Office Performance Contract (OPC) Targets</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="50px">e. <a href="https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/OPCR%20v.2016.docx">Office Performance Contract Review Form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">13. <a href="https://archive.plm.edu.ph/downloads/HRDO/Job%20Description%20Form.docx">Job Description Form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">14. <a href="https://archive.plm.edu.ph/downloads/HRDO/Statement%20of%20Duties%20and%20Responsibilities.xls">Statement of Duties and Responsibilities</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">15. <a href="https://archive.plm.edu.ph/downloads/HRDO/PES%20Form.xls">Performance Evaluation System Form</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" paddingLeft="25px">16. <a href="https://plmedu.sharepoint.com/:u:/s/MobileAppDev/EesWXDgoVmJIhdWCTzgeAbYBNifIDOthOVWZqnZK15hsRA?e=0bfmcb">Download PLM Mobile App</a></Typography>
      </Box>
      <br/>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default Download;
