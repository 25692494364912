import { AppBar, Card, CardActionArea, CardContent, CardMedia, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import orgChart from './img/lawOrg.jpg';

const OfficeLawCenter = () => {

  const osds = useRef(document.createElement("p"));
  const handleOfficeAccounting = () => {
    window.location.href="./OfficeAccounting";
  };
  const handleOfficeBudget = () => {
    window.location.href="./OfficeBudget";
  };
  const handleOfficeCUES = () => {
    window.location.href="./OfficeCUES";
  };
  const handleOfficeGSO = () => {
    window.location.href="./OfficeGSO";
  };
  const handleOfficeAudit = () => {
    window.location.href="./OfficeAudit";
  };
  const handleOfficeGraduate = () => {
    window.location.href="./OfficeGraduate";
  };
  const handleOfficeOGTS = () => {
    window.location.href="./OfficeOGTS";
  };
  const handleOfficeNSTP = () => {
    window.location.href="./OfficeNSTP";
  };
  const handleOfficeOSDS = () => {
    window.location.href="./OfficeOSDS";
  };
  const handleOfficeULC = () => {
    window.location.href="./OfficeULC";
  };
  const handleOfficeOUR = () => {
    window.location.href="./OfficeOUR";
  };
  const handleOfficeUsec = () => {
    window.location.href="./OfficeUsec";
  };
  const handleOfficeVPRAE = () => {
    window.location.href="./OfficeVPRAE";
  };
  const handleOfficePFMO = () => {
    window.location.href="./OfficePFMO";
  };
  const handleOfficePMO = () => {
    window.location.href="./OfficePMO";
  };
  const handleOfficeLawCenter = () => {
    window.location.href="./OfficeLawCenter";
  };
  const handleOfficePO = () => {
    window.location.href="./OfficePO";
  };
  const handleOfficePSO = () => {
    window.location.href="./OfficePSO";
  };
  const handleOfficeRevenue = () => {
    window.location.href="./OfficeRevenue";
  };
  const handleOfficeUHS = () => {
    window.location.href="./OfficeUHS";
  };
  const handleOfficeLibrary = () => {
    window.location.href="./OfficeLibrary";
  };
  const handleOfficeURC = () => {
    window.location.href="./OfficeURC";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 60px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="left">Offices</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "25%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper  sx={{padding: '8px'}}>
              <MenuList>
                <MenuItem onClick={handleOfficeAccounting}>Accounting Office</MenuItem>
                <MenuItem onClick={handleOfficeBudget}>Budget Office</MenuItem>
                <MenuItem onClick={handleOfficeCUES}>Center of University Extension Services</MenuItem>
                <MenuItem onClick={handleOfficeGSO}>General Services Office</MenuItem>
                <MenuItem onClick={handleOfficeAudit}>Internal Audit Office</MenuItem>
                <MenuItem onClick={handleOfficeGraduate}>Office of Graduate/Professional Studies</MenuItem>
                <MenuItem onClick={handleOfficeOGTS}>Office of Guidance & Testing Services</MenuItem>
                <MenuItem onClick={handleOfficeNSTP}>Office of NSTP</MenuItem>
                <MenuItem onClick={handleOfficeOSDS}>Office of Student Development Services</MenuItem>
                <MenuItem onClick={handleOfficeULC}>Office of the University Legal Counsel</MenuItem>
                <MenuItem onClick={handleOfficeOUR}>Office of the University Registrar</MenuItem>
                <MenuItem onClick={handleOfficeUsec}>Office of the University Secretary</MenuItem>
                <MenuItem onClick={handleOfficeVPRAE}>Office of the VP for Research</MenuItem>
                <MenuItem onClick={handleOfficePFMO}>Physical Facilities Management Office</MenuItem>
                <MenuItem onClick={handleOfficePMO}>Planning And Management Office</MenuItem>
                <MenuItem
                  onClick={() =>
                    osds.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  PLM Law Center
                </MenuItem>

                <MenuItem onClick={handleOfficePO}>Procurement Office</MenuItem>
                <MenuItem onClick={handleOfficePSO}>Property & Supplies Office</MenuItem>
                <MenuItem onClick={handleOfficeRevenue}>Revenue Generation Office</MenuItem>
                <MenuItem onClick={handleOfficeUHS}>University Health Services</MenuItem>
                <MenuItem onClick={handleOfficeLibrary}>University Library</MenuItem>
                <MenuItem onClick={handleOfficeURC}>University Research Center</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "75%", paddingLeft: '50px'}}>

      <Card >
      <CardActionArea>
        <CardMedia
          component="img"
          height="100%"
          width="100%"
          image={orgChart}
          alt="org chart"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          PLM Law Center
          </Typography>
          <Typography variant="body2" color="text.secondary">
          THE PLM LAW CENTER was created by PLM Board Resolution No. 2259, where the PLM Law Center is specifically mandated to:

<br/>Serve as an institute for continuing legal education
<br/>Conduct Legal research and publications
<br/>Promote barangay justice
<br/>Offer free legal aid services

<br/>FUNCTIONS:

<br/>Serve as an institute for continuing legal education
<br/>Conduct Legal research and publications
<br/>Promote barangay justice
<br/>Offer free legal aid services        
          
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Vision
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Non-partisan law and public policy center. Producing independent legal research, analysis, trainings / seminars and publication to help national and local government better serve their communities and the people.
        <br/>The Center currently focuses on several core topics such as the youth, women, suffrage, overseas Filipino workers and immigration laws, public international law access to justice of informal settlers and police oversight.
        <br/>The Center shall prepare law students for careers as attorneys in public service through the Legal Aid Program, a select opportunity for a small number of applicants from each class.
        <br/>Capitalizing on its location in the City of Manila, the Law Center shall explore emerging issues to shed light to the public and connect with government officials through consultations and other programs.

          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Mission
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Bridging legal profession and public service.
        <br/>The principal aim of the Center’s work is to serve as a point of resource for the government. While the Law Center has special expertise on the local government of Manila, its services aims to have a nationwide impact. The Center shall assist the local and national government in keeping its public servants abreast of the law and improve their ability to better serve the community through their in depth legal literacy.
        <br/>The Center has a non-partisan identity and does not participate in advocacy on disputed policy issues.
        <br/>Central to its mission is training, continuously educating skilled lawyers and future leaders in the legal profession as well as giving out relevant information through researches, symposiums and other extension services to the public. Connecting the law school to the community is a key part of the Center’s mission.

          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default OfficeLawCenter;
