// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Send Button */

.rcb-send-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	border: none;
	border-radius: 4px;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	transition: background-color 0.3s ease;
	height: 32px;
	width: 51px;
	margin-left: 8px;
}

/* Send Icon */

.rcb-send-icon {
	width: 60%;
	height: 60%;
	background-size: cover;
	object-fit: cover;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/SendButton/SendButton.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;CACC,oBAAoB;CACpB,mBAAmB;CACnB,uBAAuB;CACvB,yBAAyB;CACzB,YAAY;CACZ,kBAAkB;CAClB,sCAAsC;CACtC,eAAe;CACf,sCAAsC;CACtC,YAAY;CACZ,WAAW;CACX,gBAAgB;AACjB;;AAEA,cAAc;;AAEd;CACC,UAAU;CACV,WAAW;CACX,sBAAsB;CACtB,iBAAiB;CACjB,wBAAwB;CACxB,4BAA4B;CAC5B,2BAA2B;AAC5B","sourcesContent":["/* Send Button */\r\n\r\n.rcb-send-button {\r\n\tdisplay: inline-flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\ttext-transform: uppercase;\r\n\tborder: none;\r\n\tborder-radius: 4px;\r\n\tbox-shadow: 0 0 3px rgba(0, 0, 0, 0.3);\r\n\tcursor: pointer;\r\n\ttransition: background-color 0.3s ease;\r\n\theight: 32px;\r\n\twidth: 51px;\r\n\tmargin-left: 8px;\r\n}\r\n\r\n/* Send Icon */\r\n\r\n.rcb-send-icon {\r\n\twidth: 60%;\r\n\theight: 60%;\r\n\tbackground-size: cover;\r\n\tobject-fit: cover;\r\n\tbackground-size: contain;\r\n\tbackground-repeat: no-repeat;\r\n\tbackground-position: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
