// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Close Chat Icon */

.rcb-close-chat-icon {
	position: relative;
	display: inline-block;
	background-size: cover;
	width: 30px;
	height: 30px;
	border: none;
	margin-left: 5px;
	cursor: pointer;
}

.rcb-close-chat-icon::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 0;
	height: 0;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	opacity: 0;
	transition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;
}

.rcb-close-chat-icon:hover::after {
	width: 130%;
	height: 130%;
	opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/CloseChatButton/CloseChatButton.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;CACC,kBAAkB;CAClB,qBAAqB;CACrB,sBAAsB;CACtB,WAAW;CACX,YAAY;CACZ,YAAY;CACZ,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,QAAQ;CACR,SAAS;CACT,gCAAgC;CAChC,QAAQ;CACR,SAAS;CACT,oCAAoC;CACpC,kBAAkB;CAClB,UAAU;CACV,4EAA4E;AAC7E;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,UAAU;AACX","sourcesContent":["/* Close Chat Icon */\r\n\r\n.rcb-close-chat-icon {\r\n\tposition: relative;\r\n\tdisplay: inline-block;\r\n\tbackground-size: cover;\r\n\twidth: 30px;\r\n\theight: 30px;\r\n\tborder: none;\r\n\tmargin-left: 5px;\r\n\tcursor: pointer;\r\n}\r\n\r\n.rcb-close-chat-icon::after {\r\n\tcontent: \"\";\r\n\tposition: absolute;\r\n\ttop: 50%;\r\n\tleft: 50%;\r\n\ttransform: translate(-50%, -50%);\r\n\twidth: 0;\r\n\theight: 0;\r\n\tbackground-color: rgba(0, 0, 0, 0.1);\r\n\tborder-radius: 50%;\r\n\topacity: 0;\r\n\ttransition: width 0.2s ease-out, height 0.2s ease-out, opacity 0.2s ease-out;\r\n}\r\n\r\n.rcb-close-chat-icon:hover::after {\r\n\twidth: 130%;\r\n\theight: 130%;\r\n\topacity: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
