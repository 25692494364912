import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import page1 from './img/calendar1.jpg';
import page2 from './img/calendar2.jpg';
import EmailIcon from '@mui/icons-material/EmailRounded';

const onButtonClick = () => {
  const pdf = './img/calendar.pdf';
  const link = document.createElement("a");
  link.href = pdf;
  link.download = "universitycalendar.pdf"; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const onButtonClickH = () => {
  const pdf = './img/holiday.pdf';
  const link = document.createElement("a");
  link.href = pdf;
  link.download = "philippineholiday.pdf"; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const Calendar = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Academic Calendar</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
      <Button onClick={onButtonClick} variant="contained" startIcon={<EmailIcon style={{ color: 'white', padding: "0px"}} />}>
            <Typography  color="white" variant="body2">Download University Calendar</Typography>
      </Button>
      <Button  onClick={onButtonClickH} variant="contained" startIcon={<EmailIcon style={{ color: 'white', padding: "0px"}} />}>
            <Typography  color="white" variant="body2">Download Philippine Holiday</Typography>
      </Button>
      </Box>
      {/* <button onClick={onButtonClick}>Download University Calendar</button><br/>
      <button onClick={onButtonClickH}>Download Philippine Holiday</button> */}

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={page1} alt="page1" width="100%"/>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <img src={page2} alt="page2" width="100%"/>
      </Box>
      <br/>
      
      <br/>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default Calendar;
