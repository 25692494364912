import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';

const History = () => {
  const history = useRef(document.createElement("p"));

  const handleUniversityProfile = () => {
    window.location.href="./UniversityProfile";
  };

  const handleGoals = () => {
    window.location.href="./Goals";
  };

  const handleHymn = () => {
    window.location.href="./Hymn";
  };


  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">PLM History</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
          <MenuItem onClick={handleUniversityProfile}>Vision / Mission</MenuItem>
          <MenuItem onClick={handleGoals}>Goals / Key Result Areas</MenuItem>
          <MenuItem onClick={handleGoals}>Seal and Symbols</MenuItem>
          <MenuItem
            onClick={() =>
              history.current.scrollIntoView({
                behavior: "smooth",
                block: "start"       
              })
            }
          >
            PLM History
          </MenuItem>
          <MenuItem onClick={handleHymn}>University Hymn</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={history} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />   
      {/* <Box sx={{ flexGrow: 0, justifyContent: "center", background: '#002984', padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h4" alignContent="center" color="white">PLM History</Typography>
      </Box>
      <br/> */}
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">A Vision for Social Transformation</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">PLM's conception started during the administration of Mayor Arsenio H. Lacson, the first elective mayor of the City of Manila in 1951, when he approved Ordinance No. 4202 on 13 January 1960. The Municipal Board has allocated P1-Million to establish the University. The Board's committee that spearheaded and allocated funds for the creation of the city university, in support of Mayor Lacson's educational program, was chaired by then-Councilor Ernesto M. Maceda.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Mayor Lacson suddenly passed away while in office and before the fulfillment of his dream university. His then-Vice-Mayor, Antonio Villegas, succeeded him and worked for the realization of the dream university of his predecessor. On 13 February 1963, Mayor Villegas issued Executive Order No. 7, s-1963, creating a Planning and Working Committee to draw up a plan to establish the city university. The committee was chaired by Dr. Benito F. Reyes and the members were Gabriel Formoso, Leoncio Monzon, Alfredo Morales, Vicente Albano Pacis, Jose S. Roldan, and Carlos Moran Sison, with Atty. Primitivo de Leon as its secretary.</Typography>
      </Box>
      <br/>      
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Creation by National Legislation</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">In 1964, Mayor Villegas co-opted the aid of then-Congressman Justo R. Albert of the 4th congressional district of the City of Manila to sponsor a bill in the House of Representatives of the Congress of the Philippines seeking to create the city university. Filed as House Bill No. 8349 in the Lower House, the Senate version was spearheaded by Senators Gil Puyat and Camilo Osias.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">In his explanatory note for H.B. 8349, Congressman Albert stressed that "the establishment of this university by the City of Manila will spur other cities in the country to exert similar efforts so that the responsibility of educating our people may be properly located." He articulated that according to a French philosopher, "next to food, education is the greatest need of the People." He likewise emphasized that "to permit the continuing control of education in the hands of big corporations is a tragic renunciation by the Government of a sacred obligation to our people. This bill envisions a partial relief of education from the grip of profit-motivated corporations and seeks a condition in which education is solely dedicated to the better instruction of our people."</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The consolidation of the two bills was tackled during the Fourth Session of the Fifth Congress which began and was held in the City of Manila on 25 January 1965. The consolidated bill was thereafter passed by the joint Congress with the House of Representatives finally passing the same on 12 May 1965 while the Philippine Senate finally passed it on 07 June 1965. The final bill was signed by Senate President Ferdinand E. Marcos and House Speaker Cornelio T. Villareal with Mr. Regino S. Eustaquio, Secretary of the Senate, and Mr. Inocencio B. Pareja, Secretary of the House of Representatives.</Typography>
      </Box>
      <br/>      
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Presidential Approval of the Congressional Act</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">On 19 June 1965, the final bill entitled "An Act Authorizing the City of Manila to Establish and Operate the University of the City of Manila and for Other Purposes" was signed into law by President Diosdado P. Macapacal in a signing ceremony in Malacañang Palace witnessed by Atty. Primitivo de Leon, Mayor Villegas, Congressman Ramon Mitra Jr., and its main sponsor in the House of Representatives, Congressman Albert. The law was captioned as Republic Act No. 4196 which now serves as the University Charter.</Typography>
      </Box>
      <br/>      
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">A Day of Double Significance</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The birth of Pamantasan on a June 19 is made even more significant than ever by the fact that it was made to coincide with the birth of the Philippine national hero, Dr. Jose P. Rizal, who has a great aptitude for study, thereby earning several degrees and titles during his teen years with flying colors. Multi-talented as he was, Rizal's concept of the importance of education is clearly enunciated in his work entitled Instruction. For Rizal, the mission of education is to elevate the country to the highest seat of glory and to develop the people’s mentality. Since education is the foundation of society and a prerequisite for social progress, Rizal claimed that only through education could the country be saved from its sorry status. Rizal’s philosophy of education, therefore, centers on the provision of proper motivation in order to bolster the great social forces that make education a success, to create in the youth an innate desire to cultivate his intelligence and give him life eternal.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">The Seminal Direction</Typography>
      </Box>
      <br/> 
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The University was so named "Pamantasan ng Lungsod ng Maynila" from the official English name by Mayor Villegas in 1967. The Board of Regents, which is the governing body of the University, was formally formed in the same year as Mayor Villegas appointed its members. The university regents were sworn into office on 09 January 1967 which eventually conducted its first official board meeting and the election of its officers on 23 February 1967 at the Maharnilad. The composition of the first Board of Regents were: Atty. Carlos Moran Sison, chairman; Dr. Benito F. Reyes, vice-chairman; Emilio Abello, Roman F. Lorenzo, Jose S. Roldan and Primo L. Tongko, members; while Fructuoso R. Yanson served as an ex-officio member and Jose F. Sugay as its secretary. Dr. Reyes was elected as PLM's pioneer president, endowed with a herculean task of charting the roots of excellence that was to be the Pamantasan that is known today.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">On February 5, 1970, then Executive Secretary Ernesto M. Maceda, by authority of then President Ferdinand Marcos, conveyed unto the City of Manila two (2) parcels of land owned by the Republic of the Philippines. Said real properties consist the area where PLM stands today.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">And as solemnly endeared and inscribed by every PLM member to his heart, the University formally opened the University College on Monday, 17 July 1967 with an initial enrollment of 556 outstanding and bright scholars carefully selected from the upper 10% of the various public high schools in Manila. Indeed, excellence has been a "birthright" of PLM. The PLM also established the Graduate College a year later, followed by the Institute for Extra-Mural Studies.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">And the rest was history in the making.</Typography>
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default History;
