import AboutCard from './AboutCard';
import './App.css';
import Carousel1 from './Carousel';
import Footer from './Footer';
import Header from './Header';
import Landmark from './Landmark';
import PLMCarousel from './PLMCarousel';
import PLMUpdate from './PLMUpdate';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';

// Set some properties of the bot
const config = {
  botAvatar: "img.png",
  floating: true,
};

const steps = [
  {
      id: '0',
      message: 'Good Day!',

      // This calls the next id
      // i.e. id 1 in this case
      trigger: '1',
  }, {
      id: '1',

      // This message appears in
      // the bot chat bubble
      message: 'What is your name',
      trigger: '2'
  }, {
      id: '2',

      // Here we want the user
      // to enter input
      user: true,
      trigger: '3',
  }, {
      id: '3',
      message: " hi {previousValue}, how can I help you?",
      trigger: 4
  }, {
      id: '4',
      options: [

          // When we need to show a number of
          // options to choose we create alist
          // like this
          { value: 1, label: 'View Courses' },
          { value: 2, label: 'Read Articles' },

      ],
      end: true
  }
];

const Home = () => {
   return (
     <div className="App">
       <Header />
       <Carousel1 />
       <br/>
       <AboutCard />
       <PLMUpdate />
       <Landmark />
       <Footer/>
      </div>           
   );
 }

 export default Home;