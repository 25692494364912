import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeCISTM = () => {

  const cistm = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem
                  onClick={() =>
                    cistm.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Information System
                </MenuItem>
                <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
                <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={cistm} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Information Systems & Technology Management</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        On 8 December 2024, in a Board of Regent meeting, a proposal entitled " Management Reorganization of the Pamantasan ng Lungsod ng Maynila was presented. Under the Academic Cluster the Bachelor of Science in Computer Science, Bachelor of Science in Information Technology and Master in Information Technology will be under the new college which is the College of Information Systems and Technology Management.
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        On 8 December 2024, in a Board of Regent meeting, a proposal entitled " Management Reorganization of the Pamantasan ng Lungsod ng Maynila was presented. Under the Academic Cluster the Bachelor of Science in Computer Science, Bachelor of Science in Information Technology and Master in Information Technology will be under the new college which is the College of Information Systems and Technology Management. 
        </Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Information Systems and Technology Management will produce globally competent graduates that shall cater the needs of the industry in research and innovation.  
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          
        To develop and nurture students be become globally competent, innovative, and ethically responsible computing professionals engaged in life-long learning endeavors.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The graduate of College of Information Systems and Technology Management shall produce: Karunungan, Kaunlaran at Kadakilaan.
<br/>* Karunungan graduates that are practicing professionals, occupying leadership positions in their chosen fields or allied professions, guided by the values of academic excellence and integrity;
<br/>*Kaunlaran graduates that exhibit progressive professional career through life-long learning and
<br/>*Kadakilaan graduates that demonstrates social and environmental responsibility through community service.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Bachelor of Science in Computer Science program is the study of computing concepts and theories, algorithm foundations and new developments in computing.  
<br/>The program prepares students to design and create algorithms for solving computing problems.  It prepares students to acquire skills and disciplines required for designing, writing and modifying software components, modules and applications that comprise software solutions.

<br/>Careers and Profession:
<br/>* Software Engineer
<br/>*Systems Software Developer
<br/>*Research and Development computing professional
<br/>*Application Software Developer
<br/>*Computer Programmer

<br/>Bachelor of Information Technology program is the study of the utilization of both hardware and software technologies involving, planning, installing, customizing, operating, managing and administering, and maintaining information infrastructure that provides computing solution to address the needs of an organization.
<br/>The program prepares graduates to address various user needs involving the selection, development, application, integration and management of computing technologies within an organization.

<br/>Careers and Profession:
<br/>*Web and Applications Developer
<br/>*Junior Database Administrator
<br/>*Systems Administrator
<br/>*Network Engineer
<br/>*Junior Information Security Administrator
<br/>*Systems Integration Personnel
<br/>*IT Audit Assistant
<br/>*Technical Support Specialist
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Research Paper Publications and Presentations
        <br/><br/>a.        Research Conference: 2nd International Research Conference on Computer Engineering and Technology Education, ICCETE 2024
<br/>Date: April 18 to 20, 2024
<br/>Venue: Adamson University

<br/>Category: Technology in Education, and AI in Education
<br/>Research Title: Enhancing the RECTANGLE Algorithm for Text Data Encryption in Mobile Application Utilizing BBSXOR, NonceXOR and Modulo Addition
<br/>Authors: Karylle Andrei C. Velarde, Raphael P. Enciso, Vivien A. Agustine, Leisyl M. Mahusay, Jonathan C. Morano, Jamillah S. Guialil
<br/>Award: Best Paper and Best Presenter (Karylle Andrei C. Velarde)

<br/>Category: Artificial Intelligence, Machine Learning, Edge Computing and Neural Network
<br/>Research Title: An Enhancement of Support Vector Machine in Context of Sentiment Analysis Applied in Scraped Data from Tripadvisor Hotel Reviews
<br/>Authors: Lambert T. Dela Cruz, Marjorie Jasmine C. Recelis, Vivien A. Agustine, Jamillah S. Guialil, Gabriel R. Hill, Leisyl M. Mahusay, Jonathan C. Morano
<br/>Award: Best Paper and Best Presenter (Marjorie Jasmine C. Recelis)

<br/><br/>b.        Research Conference: 6th Y4iT Research Summit 2024, hosted by the UP System IT Foundation (UPSITF) 
<br/>Date: May 3, 2024
<br/>Venue: University of the Philippines, Quezon City
<br/>Research Title: An Enhancement of Austero Et Al.’s Whale Optimization   
<br/>Algorithm on Solving Course Timetabling Problems 
<br/>Authors: Christian R. Bangay, Paulyn V. Placido, Mark Anthony S. Mercado, Mark Christopher Blanco, Jonathan C. Morano, Jamillah S. Guialil, Leisyl M. Mahusay
<br/>Award: Best Poster

<br/>Award: Final List
<br/>Research Title: Enhancing Distilbert Algorithm Using CNN for Image Captioning and Defending Against Adversarial Attacks in Online Hate Speech
<br/>AUTHORS: Micah Therese T. Fabon, Jerry Luck S. Balut, Mark Christopher R. Blanco, Vivien A. Agustin, Florencio V. Contreras Jr., Jamillah S. Guialil, Jonathan C. Morano, Leisyl M. Mahusay
        
<br/>Award: Final List
<br/>Research Title: Enhancing Convolutional Neural Networks-Based Distributed Denial of Service (DDoS) Detection Systems Using L2 and Dropout Regularization in Web Servers
<br/>AUTHORS: Azrael M. Reyes, Jeremy A. Pesico, Adriana C. Raymundo, Raymund M. Dioses, Vivien A. Agustin, Florencio V. Contreras Jr., Jamillah S. Guialil, Jonathan C. Morano, Leisyl M. Mahusay
 
<br/><br/>c.        Research Conference: 11th ICT Research Congress: International Edition 
<br/>Date: May 10, 2024
<br/>Venue: University of Makati
<br/>Category: 
<br/>Research Title: An Enhancement of the Greedy Best First Search Algorithm used for Pathfinding on Two-Dimensional Video Game Grid Maps
<br/>Authors: Ngo, Moira Bernice C., Borlat, Lee Ryan Edward, Francis Arlando Atienza, Jamillah S. Guialil and Gabriel R. Hill
<br/>Award: Best Paper and Best Presenter

<br/>Category: Computing Research Category 
<br/>Research Title: Modification of Oriented Fast and Rotated BRIEF (ORB) Algorithm for Non-Real-Time Images in Pineapple Plant Disease Recognition
<br/>Authors: Estacio, Mark James C., Laurente, Christine Joy S., Blanco, Christopher R., Guialil, Jamillah S., Dioses, Raymund M.
<br/>Award: Best Paper and Best Presenter

      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeCISTM;
