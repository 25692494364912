import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';

const Goal = () => {
  const vision = useRef(document.createElement("p"));
  const goal = useRef(document.createElement("p"));

  const handleUniversityProfile = () => {
    window.location.href="./UniversityProfile";
  };

  const handleSeal = () => {
    window.location.href="./Seal";
  };

  const handleHistory = () => {
    window.location.href="./History";
  };

  const handleHymn = () => {
    window.location.href="./Hymn";
  };


  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Goals / Key Result Areas</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "24px", marginTop: "24px"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
          <MenuItem onClick={handleUniversityProfile}>Vision / Mission</MenuItem>
          <MenuItem
            onClick={() =>
              vision.current.scrollIntoView({
                behavior: "smooth",
                block: "start"       
              })
            }
          >
            Goals / Key Result Areas
          </MenuItem>
          <MenuItem onClick={handleSeal}>Seal and Symbols</MenuItem>
          <MenuItem onClick={handleHistory}>PLM History</MenuItem>
          <MenuItem onClick={handleHymn}>University Hymn</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={goal} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      {/* <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Goals/ Key Result Areas</Typography>
      </Box> */}
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">PLM's Goals and Objectives:</Typography>
      </Box>
      {/* <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">RAISE PLM</Typography>
      </Box> */}
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Provide Relevant Quality Tertiary Education</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Generate Augmented Resources</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Sustain Institutionalized Research and Extension</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Deliver Service to the Public</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Advance Employee, Faculty and Student Welfare</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Promote Public Interest</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Establish Linkages and Partnerships</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 150px 8px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">* Optimize Management of Resources</Typography>
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default Goal;
