// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chat History Container */

.rcb-view-history-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 5px;
	min-height: 35px;
	max-height: 45px;
}

/* View Chat History Button */

.rcb-view-history-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 6px 12px;
	border-radius: 20px;
	color: #adadad;
	font-size: 12px;
	background-color: #fff;
	border-color: #adadad;
	border-width: 0.5px;
	border-style: solid;
	max-width: 60%;
	cursor: pointer;
	transition: color 0.3s ease, border-color 0.3s ease;
}

.rcb-view-history-button > p{
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatHistoryButton/ChatHistoryButton.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;CACjB,mBAAmB;CACnB,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA,6BAA6B;;AAE7B;CACC,oBAAoB;CACpB,mBAAmB;CACnB,uBAAuB;CACvB,iBAAiB;CACjB,mBAAmB;CACnB,cAAc;CACd,eAAe;CACf,sBAAsB;CACtB,qBAAqB;CACrB,mBAAmB;CACnB,mBAAmB;CACnB,cAAc;CACd,eAAe;CACf,mDAAmD;AACpD;;AAEA;CACC,SAAS;CACT,gBAAgB;CAChB,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":["/* Chat History Container */\r\n\r\n.rcb-view-history-container {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tpadding-top: 10px;\r\n\tpadding-bottom: 5px;\r\n\tmin-height: 35px;\r\n\tmax-height: 45px;\r\n}\r\n\r\n/* View Chat History Button */\r\n\r\n.rcb-view-history-button {\r\n\tdisplay: inline-flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\tpadding: 6px 12px;\r\n\tborder-radius: 20px;\r\n\tcolor: #adadad;\r\n\tfont-size: 12px;\r\n\tbackground-color: #fff;\r\n\tborder-color: #adadad;\r\n\tborder-width: 0.5px;\r\n\tborder-style: solid;\r\n\tmax-width: 60%;\r\n\tcursor: pointer;\r\n\ttransition: color 0.3s ease, border-color 0.3s ease;\r\n}\r\n\r\n.rcb-view-history-button > p{\r\n\tmargin: 0;\r\n\toverflow: hidden;\r\n\ttext-overflow: ellipsis;\r\n\twhite-space: nowrap;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
