import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeCED = () => {

  const ced = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem
                  onClick={() =>
                    ced.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Education
                </MenuItem>
                <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
                <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
                <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
                <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={ced} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Education</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Education was formed out of reorganization and merging of colleges that were approved by the Board of Regents on June 4, 2015. It aspires to be one of the country's centers of excellence in the field of Education. It is committed to produce globally empowered quality graduates who are proactive and catalysts of social transformation. 
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        in June 2015, there was amalgamation of colleges under the leadership of President Ma. Leonora Vasquez De Jesus. From the College of Human Development, a new College of Education was formed out of reorganization. Two colleges and graduate schools with education programs were merged namely: the College of Human Development (CHD) particularly the Department of Teacher Education (DTE), College of Physical Education Recreation and Sports (COPERS), Emeritus College (EC) and Graduate School of Arts, Sciences and Education (GSASE) and that was approved by the BOR on June 4, 2015. The Mabuhay Integrated Learning Center (MILC) was also included since it caters the pre-school students. 
        </Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        One with the university’s vision, the College of Education aspires to pursue the principles of "Karunungan, Kaunlaran, Kadakilaan." and be one of the nation’s centers of excellence in Teacher Education.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The Administration, Faculty, and Staff of the College of Education commit:
<br/>1.        to gaining recognition as an excellent pre-service teacher-training institution.
<br/>2.        to insuring a consistently higher than national average performance on the Licensure Examination for Teachers (LET).
<br/>3.        to providing students with effective and relevant instruction that will equip them with skills required of educational institution here and abroad.
<br/>4.        to contributing to research efforts that will help improve the overall quality of education in the country.
<br/>5.        to imbibing a culture of excellence, integrity and responsibility.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Education aims:
<br/>1.        to provide effective and efficient instruction that will prepare CED students to becoming excellent teachers in the basic education level;
<br/>2.        to turn out graduates who are aware of and responsive to the demands of Philippine society and of the global community;
<br/>3.        to continuously work towards the betterment of the quality of life of the Filipinos by imparting practical knowledge and modeling timeless virtues;
<br/>4.        to encourage engagement in research that will improve quality of education in the country;
<br/>5.        to participate and encourage involvement in extension activities that respond to the basic needs of the local community and of the country.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        A.        Graduate Programs

<br/>1.        Doctor of Education major in Educational Management and Leadership (EDD-EML or DEEML)
<br/>Program Description:  This program is designed to prepare its graduates for professional careers in teaching, educational policy and evaluation, research and administration.  It also prepares them to have a strong foundation in educational leadership and management who are exposed to theories, concepts, and principles in educational leadership and management. It also aims to help enhance the cognitive and decision-making skills of educational leaders and managers who will interact and work with experts and scholars in chosen areas of specialization. 

<br/><br/>2.        Master of Arts in Education major in Biological Science (MAEd-BS or MAEBS)
<br/>Program Description: This program is specifically designed for Biology and Biological Sciences majors. It seeks to address issues on learning and teaching, and current trends in Biology or Biological Sciences Education. It provides a high-quality, accessible, responsive, and student-centered science and applied science education master’s degree incorporating teaching strategies that will improve science teaching and learning for both educators and students. 

<br/><br/>3.        Master of Arts in Education major in Chemistry (MAEd-Chem or MAECHe)
<br/>Program Description: This program is designed to enhance the competence of graduates in the teaching methodologies and science content used in educating students of Chemistry in both the secondary and college level, as well as with doing classroom-based research and field research. It offers high levels of specialized teaching and learning in pure/applied chemistry and chemistry education to equip students with scientific and pedagogical knowledge. 

<br/><br/>4.        Master of Arts in Education major in Educational Management and Leadership (MAEd-EML or MAEEML)
<br/>Program Description: The program is designed to equips educators and would-be school administrators with functional philosophies, theories, and research-based management practices based on the needs of the academic community of the region and the country to prepare them for the effective administration and supervision of schools. It provides students with knowledge, competencies, values, and skills required in leading and managing schools effectively and efficiently as well as the comprehensive understanding and knowledge of Leadership and Management aspects and approaches in the academic sector

<br/><br/>5.        Master of Arts in Education major in Physics (MAEd-Phy or MAEPHy)
<br/>Program Description: This program is designed to provide teachers and other professionals with teaching methods, science content and competency required for Physics as well as the knowledge and skills in the latest development in Physics education. It also offers high levels of specialized teaching and learning pure/applied Physics and Physics education to equip students with scientific and pedagogical expertise. 

<br/><br/>6.        Master of Arts in Education major in Social Sciences (MAEd-SS or MAESS)
<br/>Program Description:  This program is designed to provide teachers and other professionals with teaching methods and competency required for Social Sciences courses. It focuses on current events, national and international issues that have bearing on the developmental goals of the country.

<br/><br/>7.        Master of Science in Mathematics Education (MSMEd)
<br/>Program Description: This program is designed to develop mathematics educators in the basic education and college level who are competent in Math content, pedagogy and methodology. It is intended to provide advanced training in the process of learning and structuring mathematics and in creating rich environment that allow for the investigation and growth based on an undergraduate specialization in mathematics.

<br/><br/>8.        Master Arts in Special Education with Specialization in Developmental Delays (MASPED, Non-Thesis) 
<br/>Program Description: This program is designed to equip teachers and professionals with knowledge, values, and skills to handle and teach exceptional children particularly those with developmental delays. It provides teachers and other professionals with teaching methods and competency required for special and inclusive education courses.

<br/><br/>B.        Undergraduate Programs
<br/>1.        Bachelor of Elementary Education (BEEd)
<br/>Program Description: The BEED is an undergraduate teacher education degree program designed to prepare individuals intending to teach in the elementary level. It aims to develop highly motivated and competent teachers specializing in the content and pedagogy for elementary education. It draws from various allied disciplines to ensure that the graduates have a multi-disciplinary preparation in content and pedagogy.

<br/><br/>2.        Bachelor of Early Childhood Education (BECED)
<br/>Program Description: The BECED is a four-year program. Specifically, this program provides students with fundamental understanding and application of the principles of early childhood care and education, as well as experience in the application of these principles. It aims to educate individuals to be competent early childhood practitioners working with children from 0 to 8 years old in various early childhood settings (home, community, school, and workplace). It is aligned with the National Early Learning Framework (NELF), the National Competency Based Teacher Standards (NCBTS 2017) and the first key stage which is K to 3.

<br/><br/>3.        Bachelor of Special Needs Education (BSNED Generalist)
<br/>Program Description: The BSNEd is and undergraduate degree program which specializes in special needs education. This prepares teachers who will instruct and manage students with additional needs in inclusive and segregated education settings. Its graduates will be equipped to teach the basic education curriculum and alternate curricula depending on the needs of the students. Those who will teach in the elementary level will be trained across different subject areas. Those who will teach in the secondary level will provide educational support to students with additional needs in inclusive classrooms or provide educational services to students who may be enrolled in special education centers.

<br/><br/>4.        Bachelor of Secondary Education major in English (BSEd-Eng)
<br/>Program Description: The BSED-Eng is a four-year undergraduate program designed to equip learners with adequate and relevant competencies to teach in the area of specialization like English in the secondary level.  This degree program aims to develop highly motivated and competent teachers specializing in the content and pedagogy for secondary education. It draws from various allied disciplines to ensure that the graduates have a multi-disciplinary preparation in content and pedagogy.  

<br/><br/>5.        Bachelor of Secondary Education major in Filipino (BSEd-Fil)
<br/>Program Description: The BSED-Fil is a four-year undergraduate program designed to equip learners with adequate and relevant competencies to teach in the area of specialization like Filipino in the secondary level.  This degree program aims to develop highly motivated and competent teachers specializing in the content and pedagogy for secondary education. It draws from various allied disciplines to ensure that the graduates have a multi-disciplinary preparation in content and pedagogy.

<br/><br/>6.        Bachelor of Secondary Education major in Mathematics (BSEd-Math)
<br/>Program Description: The BSED-Math is a four-year undergraduate program designed to equip learners with adequate and relevant competencies to teach in the area of specialization like Mathematics in the secondary level.  This degree program aims to develop highly motivated and competent teachers specializing in the content and pedagogy for secondary education. It draws from various allied disciplines to ensure that the graduates have a multi-disciplinary preparation in content and pedagogy.

<br/><br/>7.        Bachelor of Secondary Education major in Sciences (BSEd-Sci)
<br/>Program Description: The BSED-Sci is a four-year undergraduate program designed to equip learners with adequate and relevant competencies to teach in the area of specialization like Sciences in the secondary level.  This degree program aims to develop highly motivated and competent teachers specializing in the content and pedagogy for secondary education. It draws from various allied disciplines to ensure that the graduates have a multi-disciplinary preparation in content and pedagogy.

<br/><br/>8.        Bachelor of Secondary Education major in Social Studies (BSEd-SS)
<br/>Program Description: The BSED-SS is a four-year undergraduate program designed to equip learners with adequate and relevant competencies to teach in the area of specialization like Social Studies in the secondary level.  This degree program aims to develop highly motivated and competent teachers specializing in the content and pedagogy for secondary education. It draws from various allied disciplines to ensure that the graduates have a multi-disciplinary preparation in content and pedagogy.

<br/><br/>9.        Bachelor of Physical Education (BPEd)
<br/>Program Description: The BPEd is a four-year undergraduate program.  As a teaching profession, PE is a seamless activity of designing and delivering learning activities, providing quality instruction, managing the classroom and assessing student learning, modeling as well as mentoring. This requires a deep, broad and integrated knowledge of the subject matter and its learners, which in turn informs the PE teacher’s choices in terms of meaningful curricular content, the creation of inclusive learning environments and the employment of effective learning strategies.  As a curricular subject, PE is aimed at physical literacy, which serves as the foundation for confident, enjoyable and sustained participation in a wide range of physical activities. Thus, it consists of developmentally appropriate activities. PE also aims to optimize health through learning experiences aimed at the formation of physical activity and healthy eating habits, as well as dispositions. These learning experiences likewise extend beyond the classroom which must be reinforced through programs on school and community recreation, as well as organized competitive sports. Finally, the PE draws from the knowledge base of the allied fields. The BPEd program aims at equipping graduates with the competencies to meet the psychomotor, cognitive and affective needs of learners.

        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Graduation Records, Alumni Records, Board Examination Performance Records
        </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeCED;
