// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rcb-message-prompt-container.visible {
    position: sticky;
    bottom: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    animation: popIn 0.3s ease-in-out;
    pointer-events: auto;
}

.rcb-message-prompt-container.hidden {
    opacity: 0;
    height: 0px; /* work around for hiding element while still having animation */
    visibility: hidden;
    pointer-events: none;
}

.rcb-message-prompt-text {
    padding: 6px 12px;
    border-radius: 20px;
    color: #adadad;
    font-size: 12px;
    background-color: #fff;
    border: 0.5px solid #adadad;
    cursor: pointer;
    transition: color 0.3s ease, border-color 0.3s ease;
    z-index: 9999;
}

.rcb-message-prompt-container.hidden .rcb-message-prompt-text {
    padding: 0px;
}

@keyframes popIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    70% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatBotBody/ChatMessagePrompt/ChatMessagePrompt.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,iCAAiC;IACjC,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,WAAW,EAAE,gEAAgE;IAC7E,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,sBAAsB;IACtB,2BAA2B;IAC3B,eAAe;IACf,mDAAmD;IACnD,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;MACE,qBAAqB;MACrB,UAAU;IACZ;IACA;MACE,qBAAqB;MACrB,UAAU;IACZ;IACA;MACE,mBAAmB;IACrB;AACJ","sourcesContent":[".rcb-message-prompt-container.visible {\r\n    position: sticky;\r\n    bottom: 0px;\r\n    margin: auto;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    opacity: 1;\r\n    animation: popIn 0.3s ease-in-out;\r\n    pointer-events: auto;\r\n}\r\n\r\n.rcb-message-prompt-container.hidden {\r\n    opacity: 0;\r\n    height: 0px; /* work around for hiding element while still having animation */\r\n    visibility: hidden;\r\n    pointer-events: none;\r\n}\r\n\r\n.rcb-message-prompt-text {\r\n    padding: 6px 12px;\r\n    border-radius: 20px;\r\n    color: #adadad;\r\n    font-size: 12px;\r\n    background-color: #fff;\r\n    border: 0.5px solid #adadad;\r\n    cursor: pointer;\r\n    transition: color 0.3s ease, border-color 0.3s ease;\r\n    z-index: 9999;\r\n}\r\n\r\n.rcb-message-prompt-container.hidden .rcb-message-prompt-text {\r\n    padding: 0px;\r\n}\r\n\r\n@keyframes popIn {\r\n    0% {\r\n      transform: scale(0.8);\r\n      opacity: 0;\r\n    }\r\n    70% {\r\n      transform: scale(1.1);\r\n      opacity: 1;\r\n    }\r\n    100% {\r\n      transform: scale(1);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
