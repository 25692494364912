import { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React from 'react';
import Educ from '@mui/icons-material/HistoryEdu';
import School from '@mui/icons-material/School';
import { IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import BookCard from "./book";
import axios from "axios";
import candles from './img/aboutplm.jpg';
import { grey } from '@mui/material/colors';
import myfile from './PLMUpdate.json';
 
export interface Book {
  asin: string;
  author: string;
  imagelink: string;
  rating: string;
  title: string;
  url: string;
  catagories: string;
  ISBN10: string;
}


function PLMUpdate() {
  // type resultProps = {
  //   id: string;
  //   title: string;
  //   imagelink: string;
  //   validuntil: string;
  //   readmorelink: string;
  // };

  type courseProps = {
    id: string;
    course: string;
    type: string;
    area: string;
  };

  // const [result, setResult] = useState<resultProps[]>([]);
  // const [course, setCourse] = useState<courseProps[]>([]);
  
  // const API_BASE_URL = "https://localhost";
  // const API_BASE_URL = "https://19.65.23.253";
  const [cards, setCards] = useState<Book[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  // const [slideDirection, setSlideDirection] = useState<
  //   "right" | "left" | undefined
  // >("left");

  let carouselcount = 0;
  const cardsPerPage = 3;
  const fetchBooks = async () => {
    try {
      // const response = await axios.get(`${API_BASE_URL}/plmapi/carouselold.php`);
      setCards(myfile);
      // carouselcount =  response.data.length;
    } catch (err: any) {
      console.error(err.message);
    }
  };

  // let count = 1;
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //   if (carouselcount > count) {
  //       count++
  //       handleNextPage();
  //     } else {
  //       setCurrentPage((prevPage) => 0);
  //       count = 1;
  //     }
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  const handleNextPage = () => {
    // setSlideDirection("left");
    setCurrentPage((prevPage) => prevPage + 1);
};

  const handlePrevPage = () => {
    // setSlideDirection("right");
    setCurrentPage((prevPage) => prevPage - 1);
  };

    useEffect(() => {
      fetchBooks();
      // eslint-disable-next-line
    }, []);
    const containerWidth = "100%";


  const [programtype, setProgramType] = React.useState('');
  
  const handleChange = (event: SelectChangeEvent) => {
    setProgramType(event.target.value as string);
  };

  const [areastudy, setAreaStudy] = React.useState('');
  
  const handleChangeArea = (event: SelectChangeEvent) => {
    setAreaStudy(event.target.value as string);
  };

 return (
  <>
  <hr/>
  <Box key="box1" sx={{ flexGrow: 0, justifyContent: "flex-end", background: 'white', height: '12px', display: { xs: 'none', md: 'flex' } }} />
  <Box sx={{direction: "row", display: 'flex'}}>

     <Box
       sx={{
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         alignContent: "center",
         justifyContent: "center",
          height: "250px",
         width: "100%",
         marginTop: "20px",
        //  background: grey[200],
       }}
     >
       <IconButton
         onClick={handlePrevPage}
         sx={{ margin: 2 }}
        disabled={currentPage === 0 ? true: false}
      >
         {/* this is the button that will go to the previous page you can change these icons to whatever you wish*/}
         <NavigateBeforeIcon />
       </IconButton>
       <Box sx={{ width: `${containerWidth}px`, height: "100%" }}>
         {/* this is the box that holds the cards and the slide animation,
         in this implementation the card is already constructed but in later versions you will see how the
         items you wish to use will be dynamically created with the map method*/}
         {cards.map((card, index) => (
           <Box
             key={`card-${index}`}
             sx={{
               width: "100%",
               height: "100%",
               display: currentPage === index ? "block" : "none",
             }}
           >
             {/* this is the slide animation that will be used to slide the cards in and out*/}
             <Slide direction="right" in={currentPage === index}>
               <Stack
                 spacing={2}
                 direction="row"
                 alignContent="center"
                 justifyContent="center"
                 sx={{ width: "100%", height: "100%" }}
               >
                 {/* this slices the cards array to only display the amount you have previously determined per page*/}
                 {cards
                   .slice(
                     index * cardsPerPage,
                     index * cardsPerPage + cardsPerPage
                   )
                   .map((book: Book) => (
                     <Box key={book.asin}>
                       <BookCard book={book} />
                     </Box>
                   ))}
               </Stack>
             </Slide>
           </Box>
         ))}
       </Box>
       <IconButton
         onClick={handleNextPage}
         sx={{
           margin: 2,
         }}
         disabled={ currentPage >= Math.ceil((cards.length || 0) / cardsPerPage) - 1}
       >
         <NavigateNextIcon />
       </IconButton>
     </Box>


  </Box>
    </>
 );
}

export default PLMUpdate;