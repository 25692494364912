import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeGovernment = () => {

  const gov = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
              <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
              <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
              <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
              <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
              <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
              <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
              <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
              <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
              <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
              <MenuItem
                  onClick={() =>
                    gov.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Government
                </MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={gov} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Public Administration</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Consistent with PLM’s institutional mandate, the College of Public Administration (CPA) is committed to its fundamental philosophy to uphold the values of democracy and diversity congruent with the Filipino culture and tradition. Its primordial role as a center of higher learning is not only to provide high quality education but also to be a leading institution in the political, social and cultural realms. It fosters the ideals of liberalism, constitutional democracy, respect for the dignity and rights of every person and promote an environment of solidarity, tolerance and freedom in our campus. It is dedicated to inspiring younger generation of Filipinos to pursue their most cherish dreams to gain knowledge and skills that will transform them to be the most vibrant and effective leaders in the country and around the globe.
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        With the passing of Board Resolution No. 4642 on April 12, 2019, the PLM Board of Regents approved the proposed curriculum for the Bachelor of Government Management of the College of Business and Government Management. Eventually, the College of Business and Government Management was split into College of Business and Management and the School of Government by virtue of Board Resolution No. 4716, dated Nov. 15, 2019. The SOG became an independent college thereafter. Further, Board Resolution No. 4818, dated December 17, 2020, approved the renaming of Bachelor of Government Management into Bachelor of Public Administration.   Meanwhile, pursuant to Board Resolution No. 5189 dated December 8, 2023, reorganizing the management structure of PLM, the School of Government was renamed as College of Public Administration (CPA).
 
        <br/>The CPA is now being supervised by the designated Dean, assisted by the College Secretary and Chairperson of BPA, Program Director of Master of Public Administration (MPA) and the Director of the Center for Continuing Education Program for Professional Public Service. Most of the faculty members, especially those who are handling major subjects, both full-time and part-time, are vertically aligned with Public Administration. All are master’s degree holders of public administration or other related disciplines. 
 
        </Typography>
      </Box>      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        To be recognized as the center of excellence and development for public administration and good governance by providing high quality education on par with reputable institutions.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Provide high quality and evidence-based education to empower our students to meet the demands of the 21st century. We educate and mold men and women to become public servants equipped with holistic knowledge and imbued with profound sense of justice, dedication, honesty, morality and unwavering commitment to serve humankind.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        College Objectives:
<br/>1.        To prepare the students for careers in public service;
<br/>2.        To impart public service values, principles of accountability, transparency, ethics, respect for human rights, cultural diversity, and civic involvement;
<br/>3.        To provide high quality education comparable to those offered by premier institutions of higher learning globally; and,
<br/>4.        To promote good governance and strengthen constitutional democracy.        
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          DOCTOR OF PUBLIC ADMINISTRATION (DPA)
          <br/>The Doctor of Public Administration is a comprehensive course that enhances advanced knowledge in government management and public policy. It is a combination of coursework and dissertation writing. The course is designed to be administered both as in-campus and off-campus program. Lessons are delivered through a combination of traditional and cohort-based learning. The faculty is composed of experts, scholars, and practitioners with vast experience and proficiency in government management, public policy, and organizational development.
          <br/>The course prepares the students for (1) leadership/executive positions in the government and other service-oriented institutions; (2) teaching careers in public administration and its subfields and (3) as highly competent researchers in governance and policy making.
          <br/><br/>MASTER OF PUBLIC ADMINISTRATION (MPA)
          <br/>THESIS & NON-THESIS 
          <br/>The Master of Public Administration (MPA) enhances the leadership skills of students and their ability to solve problems and manage crises in public administration. It is a combination of coursework and thesis writing or policy research paper. The course is designed to be administered both as in-campus and off-campus program. Lessons are delivered through a combination of traditional and cohort-based learning. The faculty is composed of experts, scholars, and practitioners with vast experience and proficiency in government management, public policy, and organizational development. 
          <br/>The course prepares the students for (1) leadership positions in the government and other service-oriented institutions; (2) teaching careers in public administration and its subfields in the collegiate level; and (3) in managing non-governmental organizations.
          <br/><br/>BACHELOR OF PUBLIC ADMINISTRATION (BPA)
          <br/>The Bachelor of Public Administration (BPA) program is designed to build the foundational knowledge of students in politics, government structure, administrative management, fiscal budgeting, public policy, and community development. 
          <br/>Public administration is a distinct discipline grounded on scientific principles, which foster systematic implementation of policy decision of the state. It is both an applied social science and a field of management. The study of public administration is vital in building a strong republic and in preservation of constitutional democracy. 
          <br/>The program aims to prepare the students for careers in public and foreign service and to impart the principles of accountability, transparency, ethics, respect for human rights, cultural diversity, and civic involvement in the government service. It also inculcates the same values for those who will work as staff and volunteers of civil society organizations in the name of public good.  
          <br/>The BPA Program of the PLM-College of Public Administration conforms and subscribes to the standards set forth under CHED Memorandum Order (CMO) No. 06, series of 2010, “Policies and Standards for Bachelor of Public Administration (BPA) Program.”
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Certificate of Program Compliance (COPC) for Bachelor of Public Administration issued by CHED on June 16, 2023
      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeGovernment;
