import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
function Career() {
  type resultProps = {
    id: string;
    title: string;
    imagelink: string;
    validuntil: string;
    readmorelink: string;
    description: string;
    description1: string;
    description2: string;
    date: string
  };
  
  // const [result, setResult] = useState<resultProps[]>([]);
  
  // useEffect(() => {
  //   const car = async () => {
  //     const data = await fetch("https://19.65.23.253/plmapi/career.php", {
  //       method: "GET"
  //     });
  //     const jsonData = await data.json();
  //     setResult(jsonData);
  //   };
  //   car();
  // }, []);

 return (
  <>
        <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '40px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 96px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Career</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">RESOURCE GENERATION OFFICE</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography>14 August 2024</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography sx={{ fontweight: 'underline'  }}>
        The Pamantasan ng Lungsod ng Maynila (PLM) Resource Generation Office (RGO) needs one (1) Information Officer II under Contract of Service:        
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <Box fontWeight="fontWeightBold">Office:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HUMAN RESOURCE MANAGEMENT OFFICE<br/>
        <Box fontWeight="fontWeightBold">Position:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)	Information Officer II<br/>
        <Box fontWeight="fontWeightBold">Employment Status:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contract of Service<br/>
        <Box fontWeight="fontWeightBold">Salary Per Month:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Php 36,619.00<br/>
        <Box fontWeight="fontWeightBold">Qualifications:</Box>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.   Bachelor’s Degree in Marketing or Communications or related field
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.  Strong written and verbal communication skills.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.  Knowledge of social media analytics tools and website content management systems.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.  Creative thinking and ability to generate engaging content.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.  Ability to work independently and as part of a team.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.  Attention to detail and strong organizational skills.
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.  Proven experience managing social media accounts for an organization, an educational institution would be a plus.

        <br/>Interested applicants should submit their applications, indicating the position they are applying for, via email to hrmo_applicants@plm.edu.ph  or you may send the hard copy of your application documents  to Ms. Antonette J. Gonzales , HRM Office, Pamantasan ng Lungsod ng Maynila, Intramuros, Manila  not later than 23 August 2024 :
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* Letter of Application;
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* Fully accomplished Work Experience Sheet (WES) with signature (if applicable); which can be downloaded at www.csc.gov.ph:
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* Photocopy of school credentials (Diploma and Transcript of Records); for position requiring Graduate Studies, Diploma;
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* Photocopy of relevant trainings seminars/certificates
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* Photocopy of previous and current employment certificate with duties and responsibilities                        (if applicable)
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* Photocopy of PRC License and Board Rating (if applicable)
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* Other supporting documents 

<br/>APPLICATIONS WITH INCOMPLETE DOCUMENTS SHALL NOT BE ACCEPTED AND ENTERTAINED.  ONLY DOCUMENTS RECEIVED ON OR BEFORE THE DEADLINE SHALL BE USED AS BASIS FOR EVALUATION.

<br/>The PLM is an equal opportunity employer and does not discriminate on the basis of race, color, religion, sex, political affiliation, marital status, disability, age, or other non-merit factor.

        </Typography>
      </Box>


<br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">FACULTY HIRING - College of Engineering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography sx={{ fontweight: 'underline'  }}>
        The Pamantasan ng Lungsod ng Maynila (PLM) College of Engineering needs two (2) part-time faculty personnel with the following qualifications:        
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <Box fontWeight="fontWeightBold">Department:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Civil Engineering<br/>
        <Box fontWeight="fontWeightBold">Position:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One (1) Associate Professorial Lecturer III<br/>
        <Box fontWeight="fontWeightBold">Qualifications:</Box>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Licensed Civil Engineer; 
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Graduate Master of Science in Civil Engineering. 
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Must be willing to teach via face-to-face and hybrid mode of teaching (hybrid means 50% face-to-face, 50% synchronous online).
        <Box fontWeight="fontWeightBold">Subject to teach:</Box>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Includes but not limited to General Civil Engineering Courses (i.e. Highway Engineering, Transportation Engineering, CE Project 1 and 2)
        <br/><br/>
        <Box fontWeight="fontWeightBold">Department:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Civil Engineering
        <Box fontWeight="fontWeightBold">Position:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              One (1) Assistant Professorial Lecturer IV<br/>
        <Box fontWeight="fontWeightBold">Qualifications:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Graduate of Master of Science in Construction Management. 
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Must be willing to teach via face-to-face and hybrid mode of teaching (hybrid means 50% face-to-face, 50% synchronous online).
        <Box fontWeight="fontWeightBold">Subjects to teach:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Includes, but not limited to General Civil Engineering Courses (i.e. Surveying field, Technopreneurship 101, Engineering Management, Engineering Drawing and Plans)<br/>

        <br/>Interested applicants should submit their applications, indicating the position they are applying for, via email to hrmo_applicants@plm.edu.ph  or you may send the hard copy of your application documents  to Ms. Antonette J. Gonzales , HRM Office, Pamantasan ng Lungsod ng Maynila, Intramuros, Manila  not later than 21  August 2024 :
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Letter of Application (indicate position title, item#, place of assignment, and certify that all attached documents are true and correct);
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Accomplished Personal Data Sheet 
(form may be downloaded here: https://bit.ly/GetPDSForm)
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Fully accomplished Work Experience Sheet (WES) with signature (if applicable); which can be downloaded at www.csc.gov.ph:
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Photocopy of school credentials (Diploma and Transcript of Records); for position requiring Graduate Studies, Diploma;
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Photocopy of relevant trainings seminars/certificates
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Photocopy of previous and current employment certificate with duties and responsibilities                        (if applicable)
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Photocopy of PRC License and Board Rating (if applicable)
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*	Other supporting documents 
<br/>APPLICATIONS WITH INCOMPLETE DOCUMENTS SHALL NOT BE ACCEPTED AND ENTERTAINED.  ONLY DOCUMENTS RECEIVED ON OR BEFORE THE DEADLINE SHALL BE USED AS BASIS FOR EVALUATION
<br/>The PLM is an equal opportunity employer and does not discriminate on the basis of race, color, religion, sex, political affiliation, marital status, disability, age, or other non-merit factor.

        </Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 72px"}}>
        <Typography variant="h5">FACULTY HIRING - Office of the National Service Training Program</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography sx={{ fontweight: 'bold'  }}>
        The Pamantasan ng Lungsod ng Maynila (PLM) Office of the National Service Training Program needs two (2) part-time faculty personnel  with the following qualifications:        
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: "0px 96px", display: { xs: 'none', md: 'flex' } }}>
        <Typography>
        <Box fontWeight="fontWeightBold">Position:</Box>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One (1) Lecturer I <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One (1) Lecturer III<br/>
                  <Box fontWeight="fontWeightBold">Subject to teach:</Box>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbspNSTP-Civic Welfare Training Service/Literacy Training Service<br/>
                  <Box fontWeight="fontWeightBold">Qualifications:</Box>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.	Graduate of Bachelor’s Degree, preferably a Master’s Degree holder or with Masteral units
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.	Must be willing to teach via face to face
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.	Preferably completed NSTP-related trainings such as, DRRM Training, First Aid, Drug Education, Environmental Education and Climate Change Adaptation
<br/><br/>Interested applicants should submit their applications, indicating the position they are applying for, via email to hrmo_applicants@plm.edu.ph  or you may send the hard copy of your application documents  to Ms. Antonette J. Gonzales, HRM Office, Pamantasan ng Lungsod ng Maynila, Intramuros, Manila  not later than 14 August 2024 :
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Letter of Application (indicate position title, item#, place of assignment, and certify that all attached documents are true and correct);
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Accomplished Personal Data Sheet
(form may be downloaded here: https://bit.ly/GetPDSForm)
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Fully accomplished Work Experience Sheet (WES) with signature (if applicable); which can be downloaded at www.csc.gov.ph:
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Photocopy of school credentials (Diploma and Transcript of Records); for position requiring Graduate Studies, Diploma;
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Photocopy of relevant trainings seminars/certificates
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Photocopy of previous and current employment certificate with duties and responsibilities                        (if applicable)
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Photocopy of PRC License and Board Rating (if applicable)
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Other supporting documents
<br/>APPLICATIONS WITH INCOMPLETE DOCUMENTS SHALL NOT BE ACCEPTED AND ENTERTAINED.  ONLY DOCUMENTS RECEIVED ON OR BEFORE THE DEADLINE SHALL BE USED AS BASIS FOR EVALUATION
<br/>The PLM is an equal opportunity employer and does not discriminate on the basis of race, color, religion, sex, political affiliation, marital status, disability, age, or other non-merit factor.

        </Typography>
      </Box>


      {/* {result && result.map((value) => {
         return (
           <Grid container key={value.id} justifyContent= "center">
             <Grid item xs={12} padding="16px 0px 16px 96px" alignContent="center">
               <Typography variant="h5" alignContent="center">{value.title}</Typography>
               <Typography alignItems="left">{value.description}</Typography>
               <Typography alignItems="left">{value.description1}</Typography>
               <Typography alignItems="left">{value.description2}</Typography>
             </Grid>
           </Grid>);
       })}      */}
      <br/>
      <br/>
      <br/>
      <Footer/>
       </>
 );
}

export default Career;
